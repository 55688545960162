import React, { useEffect, useRef, useState } from 'react'
import { SvgChevronDown, SvgSearch } from '../../../../images/svg'
import './scss/index.scss'
import Tippy from '@tippyjs/react'
import { Filters, ActiveFilters } from './types' // Предполагаем, что типы определены в ./types
import { useTranslation } from 'react-i18next'
import languages from '../../../../UI/languageData/languages'
import languageIcons from '../../../../UI/languageData/icons'
import FilterTooltip from '../../../../UI/Tooltip/FilterTooltip/FilterTooltip'
import SearchTooltip from '../../../../UI/Tooltip/SearchTooltip/SearchTooltip'

const getAllLanguages = (t: any): Filters[] => {
  const allLanguages: Filters[] = []
  let idCounter = 1

  languages.forEach(region => {
    region.languages.forEach(language => {
      if (language.backendName) {
        allLanguages.push({
          id: idCounter++,
          flag: languageIcons[language.code],
          text: t(language.code.toLowerCase()),
          value: language.backendName,
          active: true,
        })
      }
    })
  })

  return allLanguages
}

type FilterProps = {
  filterUpdateData: (activeFilters: ActiveFilters) => void
}

const Filter = ({ filterUpdateData }: FilterProps) => {
  const { t } = useTranslation()

  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    online: [
      { id: 1, text: t('girls.online'), value: 'online', active: true },
      { id: 2, text: t('girls.offline'), value: 'offline', active: true },
    ],
    languages: getAllLanguages(t),
    name: '',
  })

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    filterUpdateData(activeFilters)
    setIsSaving(false)
  }, [isSaving])

  const [onlineVisible, setOnlineVisible] = useState(false)
  const [languageVisible, setLanguageVisible] = useState(false)
  const [searchVisible, setSearchVisible] = useState(false)

  function handleCloseTooltipLanguage() {
    setLanguageVisible(false)
  }

  const onlineTippyRef = useRef<HTMLDivElement | null>(null)
  const languageTippyRef = useRef<HTMLDivElement | null>(null)
  const searchTippyRef = useRef<HTMLDivElement | null>(null)

  const onlineTippyInstance = useRef<any>(null)
  const languageTippyInstance = useRef<any>(null)
  const searchTippyInstance = useRef<any>(null)

  function handleCloseTooltipOnline() {
    setOnlineVisible(false)
  }

  const handleOnlineToggle = (onlineId: number) => {
    setActiveFilters(prevState => {
      const newOnlineFilters = prevState.online.map(filter => {
        if (filter.id === onlineId) {
          return { ...filter, active: !filter.active }
        }
        return filter
      })
      return {
        ...prevState,
        online: newOnlineFilters,
      }
    })
    setIsSaving(true)
  }

  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (
        onlineTippyInstance.current &&
        !onlineTippyInstance.current.popper.contains(event.target) &&
        !event.target.closest('#OnlineTooltip') &&
        onlineVisible
      ) {
        setOnlineVisible(false)
      }
      if (
        languageTippyInstance.current &&
        !languageTippyInstance.current.popper.contains(event.target) &&
        !event.target.closest('#LanguageTooltip') &&
        languageVisible
      ) {
        setLanguageVisible(false)
      }
      if (
        searchTippyInstance.current &&
        !searchTippyInstance.current.popper.contains(event.target) &&
        !event.target.closest('#SearchTooltip') &&
        searchVisible
      ) {
        setSearchVisible(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [onlineVisible, languageVisible, searchVisible])

  const handleLanguageToggle = (languageId: number) => {
    setActiveFilters(prevState => {
      const newLanguages = prevState.languages.map(lang => {
        if (lang.id === languageId) {
          return { ...lang, active: !lang.active }
        }
        return lang
      })
      return {
        ...prevState,
        languages: newLanguages,
      }
    })
  }

  const handleDeselectAllLanguages = () => {
    setActiveFilters(prevState => {
      const newLanguages = prevState.languages.map(lang => (lang.active ? { ...lang, active: !lang.active } : { ...lang, active: lang.active }))

      return {
        ...prevState,
        languages: newLanguages,
      }
    })
  }

  const handleSelectAllLanguages = () => {
    setActiveFilters(prevState => {
      const newLanguages = prevState.languages.map(lang => (lang.active ? { ...lang, active: lang.active } : { ...lang, active: !lang.active }))

      return {
        ...prevState,
        languages: newLanguages,
      }
    })
  }

  const saveDataFilteredByLanguage = () => {
    setIsSaving(true)
    setLanguageVisible(false)
  }

  const handleNameSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setActiveFilters(prevState => ({
      ...prevState,
      name: value,
    }))

    setIsSaving(true)
  }

  return (
    <div className='filter-girls'>
      <div className='filter-girls__row'>
        <Tippy
          onCreate={instance => (onlineTippyInstance.current = instance)}
          ref={onlineTippyRef}
          visible={onlineVisible}
          interactive
          trigger='click'
          hideOnClick={true}
          content={<FilterTooltip onClose={handleCloseTooltipOnline} filter={activeFilters.online} onFilterToggle={handleOnlineToggle} />}
          placement='bottom'
        >
          <div id='OnlineTooltip' onClick={() => setOnlineVisible(!onlineVisible)}>
            <div className={`online-filter ${onlineVisible ? 'rotate-down' : 'rotate-up'}`}>
              <p>{t('girls.online_title')}</p>
              <div>
                <SvgChevronDown />
              </div>
            </div>
          </div>
        </Tippy>
        <Tippy
          onCreate={instance => (languageTippyInstance.current = instance)}
          ref={languageTippyRef}
          visible={languageVisible}
          interactive
          trigger='click'
          hideOnClick={true}
          content={
            <div className='language-filter-tooltip'>
              <FilterTooltip onClose={handleCloseTooltipLanguage} filter={activeFilters.languages} onFilterToggle={handleLanguageToggle}>
                <button onClick={handleDeselectAllLanguages}>Deselect All</button>
                <button onClick={handleSelectAllLanguages}>Select All</button>
                <button onClick={saveDataFilteredByLanguage}>Save Changes</button>
              </FilterTooltip>
            </div>
          }
          placement='bottom'
        >
          <div id='LanguageTooltip' onClick={() => setLanguageVisible(!languageVisible)}>
            <div className={`language-filter ${languageVisible ? 'rotate-down' : 'rotate-up'}`}>
              <p>{t('girls.models_language')}</p>
              <div>
                <SvgChevronDown />
              </div>
            </div>
          </div>
        </Tippy>
        <Tippy
          onCreate={instance => (searchTippyInstance.current = instance)}
          ref={searchTippyRef}
          visible={searchVisible}
          interactive
          trigger='click'
          hideOnClick={true}
          content={<SearchTooltip text={activeFilters.name} handleNameSearchChange={handleNameSearchChange} />}
          placement='bottom'
        >
          <div id='SearchTooltip' onClick={() => setSearchVisible(!searchVisible)}>
            <div className='search-name'>
              <p>{t('girls.models_search')}</p>
              <div>
                <SvgSearch />
              </div>
            </div>
          </div>
        </Tippy>
      </div>
    </div>
  )
}

export default Filter
