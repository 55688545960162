import React, { FC, useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { IPopUp } from '../../types'
import './scss/index.scss'
import { getPopUpData } from 'store/reducer/popUp/selectors'
import useWebSocket from '../../../../socket/useWebSocket'
import { useUserGetFullByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { selectRole } from 'store/reducer/token/selector'
import { photoViewClient } from '../../../Photo/photoViewClient'
import { ICallEvent } from '../../../../hooks/socketTs/videoChat'
import imgSupport from '../../../../images/img/imgSupport.png'
import { SvgBackground } from '../../../../images/svg'
import useSoundPlayer from '../../../../hooks/useSoundPlayer'

const TopUpBalance: FC<IPopUp> = ({ open, closeModal }) => {
  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='modal'>
        <div className='popup'>
          <div className='popup-TopUpBalance'>
            <h1>Пополнение баланса</h1>
            <button onClick={closeModal}>Закрыть</button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default TopUpBalance
