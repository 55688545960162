import React from 'react'
import './scss/index.scss'
import documentPassport from 'images/img/example-id.jpg'
import documentFaceData from 'images/img/example-face.jpg'
import documentPassportVideo from 'images/img/passport.mp4'

interface IProps {
  example: number
}

const PhotoVerificationTooltip = ({ example }: IProps) => {
  return (
    <div className='PhotoVerificationTooltip'>
      {example === 1 ? <img src={documentPassport} alt='' /> : null}
      {example === 2 ? <img src={documentFaceData} alt='' /> : null}
      {example === 3 ? (
        <video controls width='600'>
          <source src={documentPassportVideo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      ) : null}
    </div>
  )
}

export default PhotoVerificationTooltip
