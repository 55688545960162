import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NoRoom = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/') // Редирект на главную страницу
    }, 2000) // 2000 мс = 2 секунды

    return () => clearTimeout(timer) // Очистка таймера при размонтировании компонента
  }, [navigate])

  return <div>NoRooms</div>
}

export default NoRoom
