import React, { useEffect, useRef, useState } from 'react'
import { SvgChevronDown } from '../../../../images/svg'
import './scss/index.scss'
import Tippy from '@tippyjs/react'
import { ActiveFilters } from './types'
import FilterTooltip from '../../../../UI/Tooltip/FilterTooltip/FilterTooltip'
import { t } from 'i18next'

interface IProps {
  filterUpdateData: (activeFilters: ActiveFilters) => void
}

const Filter: React.FC<IProps> = ({ filterUpdateData }) => {
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    online: [
      { id: 1, text: 'online', active: true },
      { id: 2, text: 'offline', active: true },
    ],
    friends: [
      { id: 1, text: 'friends', active: true },
      { id: 2, text: 'no_friends', active: true },
    ],
  })

  useEffect(() => {
    filterUpdateData(activeFilters)
  }, [activeFilters])

  // Online Tooltip
  const [onlineVisible, setOnlineVisible] = useState(false)
  const onlineTippyRef = useRef<HTMLDivElement | null>(null)
  const onlineTippyInstance = useRef<any>(null)

  // Friends Tooltip
  const [friendsVisible, setFriendsVisible] = useState(false)
  const friendsTippyRef = useRef<HTMLDivElement | null>(null)
  const friendsTippyInstance = useRef<any>(null)

  function handleCloseTooltipOnline() {
    setOnlineVisible(false)
  }

  function handleCloseTooltipFriends() {
    setFriendsVisible(false)
  }

  const handleOnlineToggle = (onlineId: number) => {
    setActiveFilters(prevState => {
      const newOnlineFilters = prevState.online.map(filter => {
        if (filter.id === onlineId) {
          return { ...filter, active: !filter.active }
        }
        return filter
      })
      return { ...prevState, online: newOnlineFilters }
    })
  }

  const handleFriendsToggle = (friendsId: number) => {
    setActiveFilters(prevState => {
      const newFriendsFilters = prevState.friends.map(filter => {
        if (filter.id === friendsId) {
          return { ...filter, active: !filter.active }
        }
        return filter
      })
      return { ...prevState, friends: newFriendsFilters }
    })
  }

  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (
        onlineTippyInstance.current &&
        !onlineTippyInstance.current.popper.contains(event.target) &&
        !event.target.closest('#OnlineTooltip') &&
        onlineVisible
      ) {
        setOnlineVisible(false)
      }

      if (
        friendsTippyInstance.current &&
        !friendsTippyInstance.current.popper.contains(event.target) &&
        !event.target.closest('#FriendsTooltip') &&
        friendsVisible
      ) {
        setFriendsVisible(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [onlineVisible, friendsVisible])

  return (
    <div className='filter-people'>
      <div className='filter-people__row'>
        {/* Tooltip for Online */}
        <Tippy
          onCreate={instance => (onlineTippyInstance.current = instance)}
          ref={onlineTippyRef}
          visible={onlineVisible}
          interactive
          content={<FilterTooltip onClose={handleCloseTooltipOnline} filter={activeFilters.online} onFilterToggle={handleOnlineToggle} />}
          placement='bottom'
        >
          <div id='OnlineTooltip' onClick={() => setOnlineVisible(!onlineVisible)}>
            <div className={`online-filter ${onlineVisible ? 'rotate-down' : 'rotate-up'}`}>
              <p>{t('item.online')}</p>
              <SvgChevronDown />
            </div>
          </div>
        </Tippy>

        {/* Tooltip for Friends */}
        <Tippy
          onCreate={instance => (friendsTippyInstance.current = instance)}
          ref={friendsTippyRef}
          visible={friendsVisible}
          interactive
          content={<FilterTooltip onClose={handleCloseTooltipFriends} filter={activeFilters.friends} onFilterToggle={handleFriendsToggle} />}
          placement='bottom'
        >
          <div id='FriendsTooltip' onClick={() => setFriendsVisible(!friendsVisible)}>
            <div className={`friends-filter ${friendsVisible ? 'rotate-down' : 'rotate-up'}`}>
              <p>Friends</p>
              <SvgChevronDown />
            </div>
          </div>
        </Tippy>
      </div>
    </div>
  )
}

export default Filter
