import { log } from '@livekit/components-core'
import * as React from 'react'
import type { LocalAudioTrack, LocalVideoTrack } from 'livekit-client'
import { MediaDeviceSelect } from '@livekit/components-react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { SvGArrowWhiteTop } from '../../../../../../images/svg'

export interface MediaDeviceMenuProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: MediaDeviceKind
  initialSelection?: string
  onActiveDeviceChange?: (kind: MediaDeviceKind, deviceId: string) => void
  tracks?: Partial<Record<MediaDeviceKind, LocalAudioTrack | LocalVideoTrack | undefined>>
  requestPermissions?: boolean
}

export function MediaDeviceMenu({
  kind,
  initialSelection,
  onActiveDeviceChange,
  tracks,
  requestPermissions = false,
  ...props
}: MediaDeviceMenuProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleActiveDeviceChange = (kind: MediaDeviceKind, deviceId: string) => {
    log.debug('handle device change')
    setIsOpen(false)
    onActiveDeviceChange?.(kind, deviceId)
  }

  return (
    <Tippy
      interactive
      visible={isOpen}
      onClickOutside={() => setIsOpen(false)}
      placement='top'
      content={
        !props.disabled && (
          <div className='lk-device-menu-custom'>
            {kind ? (
              // @ts-ignore
              <MediaDeviceSelect
                initialSelection={initialSelection}
                onActiveDeviceChange={deviceId => handleActiveDeviceChange(kind, deviceId)}
                kind={kind}
                track={tracks?.[kind]}
                requestPermissions={requestPermissions}
              />
            ) : (
              <>
                <div className='lk-device-menu-heading-custom'>Audio inputs</div>
                {/*// @ts-ignore*/}
                <MediaDeviceSelect
                  kind='audioinput'
                  onActiveDeviceChange={deviceId => handleActiveDeviceChange('audioinput', deviceId)}
                  track={tracks?.audioinput}
                  requestPermissions={requestPermissions}
                />
                <div className='lk-device-menu-heading-custom'>Video inputs</div>
                {/*// @ts-ignore*/}
                <MediaDeviceSelect
                  kind='videoinput'
                  onActiveDeviceChange={deviceId => handleActiveDeviceChange('videoinput', deviceId)}
                  track={tracks?.videoinput}
                  requestPermissions={requestPermissions}
                />
              </>
            )}
          </div>
        )
      }
      onShow={() => setIsOpen(true)}
      onHide={() => setIsOpen(false)}
    >
      <button
        className={`lk-button lk-button-custom-arrow ${isOpen ? 'open' : ''}`}
        aria-pressed={isOpen}
        {...props}
        onClick={() => setIsOpen(prev => !prev)}
      >
        <SvGArrowWhiteTop className={`arrow-icon ${isOpen ? 'rotated' : ''}`} />
      </button>
    </Tippy>
  )
}
