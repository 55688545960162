import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './scss/index.scss'
import AgeBlockPopUp from './AgeBlock/AgeBlockPopUp'
import { getPopUpClose, getPopUpTwoClose } from 'store/reducer/popUp/reducer'
import { getPopUp } from 'store/reducer/popUp/selectors'
import Complaint from './Complaint/Complaint'
import AdminCreatePrivateChat from './AdminCreatePrivateChat/AdminCreatePrivateChat'
import Language from './Language/Language'
import Calls from './VideoPopUp/Calls/Calls'
import SettingsVideo from './VideoPopUp/SettingsVideo/SettingsVideo'
import EnterRoom from './VideoPopUp/EnterRoom/EnterRoom'
import { PopUpPresent } from './VideoPopUp/PresentModel/PresentModel'
import SocketTest from './SocketTest/SocketTest'
import LoadingPopUp from './Loading/LoadingPopUp'
import GirlPopUp from './VideoPopUp/GirlPopUp/GirlPopUp'
import RateVideoCall from './VideoPopUp/RateVideoCall/RateVideoCall'
import TopUpBalance from './VideoPopUp/TopUpBalance/TopUpBalance'
import GiftAnimations from './GiftAnimations/GiftAnimations'
import MediaPermissionMonitorPopUp from './VideoPopUp/MediaPermissionMonitorPopUp/MediaPermissionMonitorPopUp'
import ErrorSocket from './ErrorSocket/ErrorSocket'

const PopUp = () => {
  const dispatch = useDispatch()
  const popUpState = useSelector(getPopUp)
  const [open, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(false)
    dispatch(getPopUpClose())
  }
  const closeModalTwo = () => {
    // setOpen(false)
    dispatch(getPopUpTwoClose())
  }
  useEffect(() => {
    setOpen(true)
  }, [popUpState.popUpText])
  useEffect(() => {
    setOpen(false)
  }, [])
  const popUpContent = () => {
    switch (popUpState.popUpText) {
      case 'ageBlock':
        return <AgeBlockPopUp open={open} closeModal={closeModal} />
      case 'errorSocket':
        return <ErrorSocket open={open} closeModal={closeModal} />
      case 'loading':
        return <LoadingPopUp open={open} closeModal={closeModal} />
      case 'language':
        return <Language open={open} closeModal={closeModal} />
      case 'giftAnimations':
        return <GiftAnimations open={open} closeModal={closeModal} />
      case 'complaint':
        return <Complaint open={open} closeModal={closeModal} />
      case 'GirlPopUp':
        return <GirlPopUp open={open} closeModal={closeModal} />
      case 'topUpBalance':
        return <TopUpBalance open={open} closeModal={closeModal} />
      case 'AdminCreatePrivateChat':
        return <AdminCreatePrivateChat open={open} closeModal={closeModal} />
      case 'calls':
        return <Calls open={open} closeModal={closeModal} />
      case 'rateVideoCall':
        return <RateVideoCall open={open} closeModal={closeModal} />
      case 'enterRoom':
        return <EnterRoom open={open} closeModal={closeModal} />
      case 'settingsVideo':
        return <SettingsVideo open={open} closeModal={closeModal} />
      case 'presentPopUp':
        return <PopUpPresent open={open} closeModel={closeModal} />
      case 'socketTest':
        return <SocketTest open={open} closeModal={closeModal} />
      case 'mediaPermissionMonitorPopUp':
        return <MediaPermissionMonitorPopUp open={open} closeModal={closeModal} />
      default:
        return null
    }
  }
  const popUpContentTwo = () => {
    switch (popUpState.popUpTextTwo) {
      case 'mediaPermissionMonitorPopUp':
        return <MediaPermissionMonitorPopUp open={open} closeModal={closeModalTwo} />
      default:
        return null
    }
  }
  return (
    <div>
      {popUpContent()}
      {popUpContentTwo()}
    </div>
  )
}

export default PopUp
