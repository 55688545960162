import React, { useState, useCallback } from 'react'
import './scss/index.scss'
import { SearchApiArg, useSearchQuery } from 'store/apiMain/mainApi'
import Filters from './Filters/Filters'
import Item from './Item/Item'
import Pagination from './Pagination/Pagination' // Импортируем новую пагинацию

const ParentComponent: React.FC = () => {
  const [filters, setFilters] = useState<SearchApiArg>({
    withCurrentUserIdToConnect: true,
    withActive: true,
    page: 0,
    pageSize: 10,
  })

  const { data: dataRooms, isLoading } = useSearchQuery(filters)

  // Функция для смены страницы
  const handleChangePage = useCallback(
    (newPage: number) => {
      setFilters(prev => ({
        ...prev,
        page: newPage,
      }))
    },
    [setFilters],
  )

  // Функция для смены количества записей на странице
  const handleChangePageSize = useCallback(
    (newPageSize: number) => {
      setFilters(prev => ({
        ...prev,
        pageSize: newPageSize,
        page: 0, // Сбрасываем на первую страницу
      }))
    },
    [setFilters],
  )

  return (
    <div className='adminRooms'>
      <h1>Data Rooms</h1>

      <Filters filters={filters} onChangeFilters={setFilters} />

      {isLoading && <div>Loading...</div>}

      {dataRooms && (
        <>
          <Pagination
            // Текущие значения берем из нашего state:
            page={filters.page || 0}
            pageSize={filters.pageSize || 10}
            // А общую информацию берем из бэкенда:
            totalPages={dataRooms.totalPages}
            totalElements={dataRooms.totalElements}
            // Функции, которые будут менять наш стейт:
            onChangePage={handleChangePage}
            onChangePageSize={handleChangePageSize}
          />
          <Item dataRooms={dataRooms.content} />
        </>
      )}
    </div>
  )
}

export default ParentComponent
