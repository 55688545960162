import { popUpOpen } from 'store/reducer/popUp/reducer'
import { store } from 'store/store'
import { toastError, toastInfo } from '../../elements/Notification/Notification'
import { selectRole } from 'store/reducer/token/selector'

const actionsError = (errorData: any) => {
  const state = store.getState()
  const role = selectRole(state)

  if (errorData?.error?.data?.type === 'ACCOUNT_COINS_BALANCE_INSUFFICIENT') {
    if (role === 'CLIENT') {
      store.dispatch(popUpOpen('topUpBalance'))
      toastError(errorData?.error?.data?.title)
    } else {
      toastInfo('У клиента закончились деньги')
    }
  }
  return null
}

export default actionsError
