import React, { FC, useEffect, useRef, useState } from 'react'
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { IPopUp } from '../../types'
import './scss/index.scss'
import { getPopUpData } from 'store/reducer/popUp/selectors'
import useWebSocket from '../../../../socket/useWebSocket'
import { useUserGetFullByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { selectRole } from 'store/reducer/token/selector'
import { photoViewClient } from '../../../Photo/photoViewClient'
import { ICallEvent } from '../../../../hooks/socketTs/videoChat'
import imgSupport from '../../../../images/img/imgSupport.png'
import { SvgBackground } from '../../../../images/svg'
import useSoundPlayer from '../../../../hooks/useSoundPlayer'
import { useMediaPermissions } from '../../../../hooks/useMediaPermissions'
import { toastInfo } from '../../../../elements/Notification/Notification'

const Calls: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const { handleCall } = useWebSocket()
  const selectedDataPopUp = useSelector(getPopUpData)?.data as ICallEvent | undefined
  const [selectedDataPop, setSelectedDataPop] = useState<ICallEvent | null>(null)

  const { data: userMe } = useUserGetFullMeQuery()
  const { data: targetUserData } = useUserGetFullByIdQuery({
    // @ts-ignore
    userId:
      userMe?.userData?.user?.id === selectedDataPopUp?.body?.callerUserId
        ? selectedDataPopUp?.body.targetUserId
        : selectedDataPopUp?.body.callerUserId,
  })

  const { playSound, stopAllSounds } = useSoundPlayer(false)
  const { ensurePermissionsAndExecute } = useMediaPermissions()
  const role = useSelector(selectRole)

  // Реф, где будет храниться идентификатор таймера
  const callTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (selectedDataPopUp) {
      setSelectedDataPop(selectedDataPopUp)
    }
  }, [selectedDataPopUp])

  // Логика автоотключения через 20 секунд
  useEffect(() => {
    if (open) {
      callTimeoutRef.current = setTimeout(() => {
        if (role === 'MODEL' && selectedDataPopUp?.body?.callerUserRole === 'MODEL') {
          toastInfo('Абонент не отвечает!')
        }
        if (role === 'CLIENT' && selectedDataPopUp?.body?.callerUserRole === 'MODEL') {
          toastInfo('Пропущенный звонок!')
        }
        if (role === 'CLIENT' && selectedDataPopUp?.body?.callerUserRole === 'CLIENT') {
          toastInfo('Абонент не отвечает!')
        }
        if (role === 'MODEL' && selectedDataPopUp?.body?.callerUserRole === 'CLIENT') {
          toastInfo('Пропущенный звонок!')
        }
        handleDecline()
        closeModal()
      }, 15000)
    }

    // При размонтировании/закрытии попапа очищаем таймер
    return () => {
      if (callTimeoutRef.current) {
        clearTimeout(callTimeoutRef.current)
        callTimeoutRef.current = null
      }
    }
  }, [open, closeModal])

  const handleAccept = () => {
    // Если пользователь принял звонок — очищаем таймер, чтобы он не сработал
    if (callTimeoutRef.current) {
      clearTimeout(callTimeoutRef.current)
      callTimeoutRef.current = null
    }
    ensurePermissionsAndExecute(() => {
      stopAllSounds()
      if (selectedDataPop?.body?.callerUserId) {
        handleCall(selectedDataPop.body.callerUserId, 'CALL_ACCEPT')
      }
    })
  }

  const handleDecline = () => {
    // Если пользователь отклонил звонок — также очищаем таймер
    if (callTimeoutRef.current) {
      clearTimeout(callTimeoutRef.current)
      callTimeoutRef.current = null
    }
    if (selectedDataPop?.body?.callerUserId) {
      handleCall(selectedDataPop.body.callerUserId, 'CALL_CANCEL')
    }
    stopAllSounds()
    closeModal()
  }

  const getPhotoAndName = () => {
    if (!targetUserData) return { photo: null, name: 'Unknown' }
    const callerUserRole = selectedDataPopUp?.body?.callerUserRole

    if (callerUserRole === 'CLIENT' && role === 'MODEL') {
      return {
        photo: photoViewClient(targetUserData?.client?.photoId),
        name: targetUserData?.client?.name || 'Unknown',
      }
    } else if (callerUserRole === 'CLIENT' && role === 'CLIENT') {
      return {
        photo: <img src={targetUserData?.profilePhoto?.file?.url} alt='' />,
        name: targetUserData?.model?.name || 'Unknown',
      }
    } else if (callerUserRole === 'MODEL' && role === 'CLIENT') {
      return {
        photo: <img src={targetUserData?.profilePhoto?.file?.url} alt='' />,
        name: targetUserData?.model?.name || 'Unknown',
      }
    } else if (callerUserRole === 'MODEL' && role === 'MODEL') {
      return {
        photo: photoViewClient(targetUserData?.client?.photoId),
        name: targetUserData?.client?.name || 'Unknown',
      }
    } else if (callerUserRole === 'STAFF') {
      return {
        photo: <img src={imgSupport} alt='' />,
        name: 'Admin',
      }
    } else if (role === 'STAFF') {
      return {
        photo: <img src={imgSupport} alt='' />,
        name: 'Admin',
      }
    } else {
      return { photo: null, name: 'Unknown' }
    }
  }

  const { photo, name } = getPhotoAndName()

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='modal'>
        <div className='popup'>
          <div className='popup-calls'>
            <div className='caller-info'>
              <div className='caller-details'>
                <h3>{name}</h3>
              </div>
              <div className='caller-info_background'>
                <SvgBackground />
              </div>
              <div className='img-box'>{photo}</div>
            </div>
            <div className='call-actions'>
              {userMe?.userData?.user?.id === selectedDataPopUp?.body?.callerUserId ? (
                <button className='decline-call' onClick={handleDecline}>
                  Отклонить / Завершить
                </button>
              ) : (
                <>
                  <button className='accept-call' onClick={handleAccept}>
                    Принять
                  </button>
                  <button className='decline-call' onClick={handleDecline}>
                    Отклонить / Завершить
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default Calls
