// videoChatSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IVideoChatStore } from 'store/reducer/videoChat/types'
import { RoomResponse } from 'store/apiMain/mainApi'
import { IRoomsStore } from 'store/reducer/rooms/types'

const initialState: IRoomsStore = {
  rooms: [],
}

const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setRooms: (state, action: PayloadAction<RoomResponse[]>) => {
      const roomMap: Map<string, RoomResponse> = new Map(state.rooms.map((room: RoomResponse) => [room.id, room] as [string, RoomResponse]))
      action.payload.forEach((room: RoomResponse) => {
        roomMap.set(room.id, room)
      })
      state.rooms = Array.from(roomMap.values())
    },
    setRoom: (state, action: PayloadAction<RoomResponse>) => {
      const roomIndex = state.rooms.findIndex((room: RoomResponse) => room.id === action.payload.id)
      if (roomIndex !== -1) {
        // Обновляем существующую комнату
        state.rooms[roomIndex] = action.payload
      } else {
        state.rooms.push(action.payload)
      }
    },
    setRoomParticipants: (
      state,
      action: PayloadAction<{
        localParticipant?: string
        remoteParticipant?: string
      }>,
    ) => {
      const { localParticipant, remoteParticipant } = action.payload

      // Предположим, что localParticipant - это всегда владелец комнаты (ownerUserId)
      const roomIndex = state.rooms.findIndex((room: RoomResponse) => room.ownerUserId === localParticipant)

      // Если нашли такую комнату и действительно есть второй участник
      if (roomIndex !== -1 && remoteParticipant) {
        const currentRoom = state.rooms[roomIndex]

        // Добавляем participantId
        currentRoom.participantId = remoteParticipant
        // Если владелец – STAFF, можно задать доп. логику.

        let newParticipantRole: RoomResponse['participantRole']
        if (currentRoom.ownerUserRole === 'CLIENT') {
          newParticipantRole = 'MODEL'
        } else if (currentRoom.ownerUserRole === 'MODEL') {
          newParticipantRole = 'CLIENT'
        } else {
          // Если STAFF или другой вариант, определите нужную логику. К примеру:
          newParticipantRole = 'CLIENT'
        }

        currentRoom.participantRole = newParticipantRole
        state.rooms[roomIndex] = currentRoom
      }
    },
    deleteRoom: (state, action: PayloadAction<string>) => {
      state.rooms = state.rooms.filter(room => room.id !== action.payload)
    },
    resetSetRooms: () => initialState,
  },
})

export const { setRooms, deleteRoom, setRoom, setRoomParticipants } = roomsSlice.actions

export default roomsSlice.reducer
