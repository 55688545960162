import { SvgBlockUser } from '../../../../../images/svg'
import { useDispatch } from 'react-redux'
import { RelationshipDeleteByIdApiArg, RelationshipFullDto, RelationshipPartialUpdateByIdApiArg } from 'store/apiMain/mainApi'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { photoViewUser } from '../../../../../UI/PhotoViewUser/PhotoViewUser'
import { sendMessages } from 'store/reducer/messages/reducer' // Import useTranslation
import './scss/index.scss'

const Item = ({ role, item, filter, friendsDelete, friendsUpdate }: any) => {
  const { t } = useTranslation() // Initialize the translation hook
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const dispatchRoleText = (roleActions: any) => {
    if (item.relationship.status === 'FRIEND_REQUEST') {
      if (roleActions === role) {
        return t('item.youSentRequest')
      } else {
        return t('item.youReceivedRequest')
      }
    }
    if (item.relationship.status === 'BLOCKED') {
      if (roleActions === role) {
        return t('item.youBlocked')
      } else {
        return t('item.youBlockedBy')
      }
    }
    if (item.relationship.status === 'FRIENDS') {
      return 'You are friends'
    }
  }

  const renderActionButtons = (text: any, item: RelationshipFullDto) => {
    if (text === 'FRIENDS') {
      return (
        <>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
                relationshipPartialUpdateDto: {
                  type: 'BLOCK',
                },
              }
              friendsUpdate(payloadButton)
            }}
            className='item__btn-block-user'
          >
            <SvgBlockUser />
          </button>
          <div className='item__buttons'>
            <button
              onClick={event => {
                event.stopPropagation()
                if (item.relationship?.clientId && item.relationship?.modelId) {
                  const obg = {
                    recipientId: item.relationship?.clientId,
                    senderId: item.relationship?.modelId,
                  }
                  dispatch(sendMessages(obg))
                  navigate('/messages')
                }
              }}
              className='item__button_blue'
            >
              Message
            </button>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button_grey'
            >
              Delete
            </button>
          </div>
        </>
      )
    }

    if (text === 'FRIEND_REQUEST') {
      if (item.relationship?.initiatedBy === role) {
        return (
          <div className='item__buttons'>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button_grey'
            >
              Cancel
            </button>
          </div>
        )
      } else {
        return (
          <div className='item__buttons'>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                  relationshipPartialUpdateDto: {
                    type: 'APPROVE_FRIEND_REQUEST',
                  },
                }
                friendsUpdate(payloadButton)
              }}
              className='item__button_blue'
            >
              Accept
            </button>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button_grey'
            >
              Reject
            </button>
          </div>
        )
      }
    }

    if (text === 'ONLINE') {
      return (
        <>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
                relationshipPartialUpdateDto: {
                  type: 'BLOCK',
                },
              }
              friendsUpdate(payloadButton)
            }}
            className='item__btn-block-user'
          >
            <SvgBlockUser />
          </button>
          <div className='item__buttons'>
            <button
              onClick={() => {
                navigate('/messages')
              }}
              className='item__button_blue'
            >
              Message
            </button>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button_grey'
            >
              Delete
            </button>
          </div>
        </>
      )
    }

    if (text === 'FAVORITES') {
      return (
        <>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
                relationshipPartialUpdateDto: {
                  type: 'BLOCK',
                },
              }
              friendsUpdate(payloadButton)
            }}
            className='item__btn-block-user'
          >
            <SvgBlockUser />
          </button>
          <div className='item__buttons'>
            <button
              onClick={event => {
                event.stopPropagation()
                if (item.relationship?.clientId && item.relationship?.modelId) {
                  const obg = {
                    recipientId: item.relationship?.clientId,
                    senderId: item.relationship?.modelId,
                  }
                  dispatch(sendMessages(obg))
                  navigate('/messages')
                }
              }}
              className='item__button_blue'
            >
              Message
            </button>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button_grey'
            >
              Delete
            </button>
          </div>
        </>
      )
    }

    if (text === 'BLOCKED' && role === item?.relationship?.initiatedBy) {
      return (
        <div className='item__buttons'>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipDeleteByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
              }
              friendsDelete(payloadButton)
            }}
            className='item__button_grey'
          >
            {t('item.unblock')}
          </button>
        </div>
      )
    }
  }

  const actionButtons = renderActionButtons(filter, item)

  return (
    <div
      onClick={event => {
        event.stopPropagation()
        if (role === 'CLIENT') {
          navigate(`/girl/${item.model?.model.identifier}`)
        }
        // if (role === 'MODEL') {
        //   navigate(`/girl/${item.client.client.id}`)
        // }
      }}
      key={item.relationship?.id}
      className={role === 'CLIENT' ? 'friends__content-item model-link' : 'friends__content-item'}
    >
      <div className='flex-column'>
        <div className='item__img'>
          {role !== 'MODEL' ? <img src={item?.model?.profilePhoto?.file?.url} alt='' /> : photoViewUser(item?.client?.client?.photoId)}
        </div>
        <div className='item__content flex-column'>
          <h6 className='item__content-title'>{role === 'CLIENT' ? item.model?.model?.name : item.client?.client?.name}</h6>
          <p className='item__content-text'>{dispatchRoleText(item.relationship?.initiatedBy)}</p>
        </div>
        {actionButtons}
      </div>
    </div>
  )
}

export default Item
