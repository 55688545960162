import React from 'react'
import './scss/index.scss'
import Tippy from '@tippyjs/react'
import TextTooltipInfo from '../Tooltip/TextTooltipInfo/TextTooltipInfo'
import { SvgAway, SvgOffline, SvgOnCall, SvgOnline, SvgWaitingInRoom } from '../../images/svg'

// Типы статусов
type StatusType = 'OFFLINE' | 'ONLINE' | 'AWAY' | 'ON_CALL' | 'WAITING_IN_ROOM' | 'noStatus'

interface IProps {
  status: StatusType
}

const Status = ({ status }: IProps) => {
  const displayStatus = status ?? 'noStatus'

  // Логика для текста, отображаемого в подсказке
  const renderText = () => {
    switch (status) {
      case 'ONLINE':
        // return 'Пользователь сейчас онлайн'
        return 'Онлайн'
      case 'AWAY':
        // return 'Пользователь отошел'
        return 'Был(а) недавно в сети'
      case 'OFFLINE':
        // return 'Пользователь оффлайн'
        return 'Оффлайн'
      case 'WAITING_IN_ROOM':
        return 'Модель ожидает тебя в комнате, заходи к ней сейчас'
      case 'ON_CALL':
        // return 'Пользователь на звонке'
        return 'На звонке'
      default:
        return 'Статус неизвестен'
    }
  }
  const renderIcon = () => {
    switch (status) {
      case 'ONLINE':
        return <SvgOnline className='status-icon' />
      case 'AWAY':
        return <SvgAway className='status-icon' />
      case 'OFFLINE':
        return <SvgOffline className='status-icon' />
      case 'WAITING_IN_ROOM':
        return <SvgWaitingInRoom className='status-icon' />
      case 'ON_CALL':
        return <SvgOnCall className='status-icon' />
      default:
        return null
    }
  }

  return (
    <div className='status'>
      <div className='status__box'>
        <Tippy
          interactive
          trigger='mouseenter'
          content={<TextTooltipInfo text={renderText()} />}
          // placement={status === 'WAITING_IN_ROOM' ? 'right' : 'top'}
          placement={status === 'WAITING_IN_ROOM' ? 'right' : 'auto'}
        >
          <div className={`status-indicator ${displayStatus?.toLowerCase()}`}>{renderIcon()}</div>
        </Tippy>
      </div>
    </div>
  )
}

export default Status
