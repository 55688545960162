import React, { useEffect, useState } from 'react'
import './scss/index.scss'
import SideBar from './SideBar/SideBar'
import Content from './Content/Content'
import { RelationshipGetCountApiArg, useRelationshipGetCountQuery } from 'store/apiMain/mainApi'
import { useSelector } from 'react-redux'
import { getSocketNotification } from '../../../store/reducer/socket/selector'
import { selectorUpdateFriends } from '../../../store/reducer/friends/selectors'

type SidebarFilter = {
  sidebar: 'FRIENDS' | 'FRIEND_REQUEST' | 'BLOCKED' | 'ONLINE' | 'FAVORITES'
}

const Friends = () => {
  const [sideBarFilterValue, setSideBarFilterValue] = useState<SidebarFilter['sidebar']>('FRIENDS')

  const payloadRequest: RelationshipGetCountApiArg = {
    statuses: ['FRIEND_REQUEST'],
  }
  const { data: dataRequest, refetch: refetchTabs } = useRelationshipGetCountQuery(payloadRequest)
  const updateFriends = useSelector(selectorUpdateFriends)

  useEffect(() => {
    refetchTabs()
  }, [updateFriends])
  const socketNotification = useSelector(getSocketNotification)
  useEffect(() => {
    refetchTabs()
  }, [socketNotification])

  return (
    <>
      <main className='friends'>
        <SideBar sideBarFilterValue={sideBarFilterValue} setSideBarFilterValue={setSideBarFilterValue} dataRequest={dataRequest} />
        <Content filter={sideBarFilterValue} refetchTabs={refetchTabs} />
      </main>
    </>
  )
}

export default Friends
