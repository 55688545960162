import React, { FC } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { IPopUp } from '../types'
import './scss/index.scss'
import { ObjectInspector } from 'react-inspector'
import { useSelector } from 'react-redux'
import { getSocketTestDataSelector } from 'store/reducer/socketTest/selector'

const customTheme = {
  BASE_FONT_FAMILY: 'Source Code Pro, monospace',
  BASE_FONT_SIZE: 14,
  OBJECT_NAME: '#4ec9b0',
  OBJECT_VALUE_STRING: '#ce9178',
  OBJECT_VALUE_NUMBER: '#b5cea8',
  OBJECT_VALUE_BOOLEAN: '#569cd6',
  OBJECT_VALUE_NULL: '#569cd6',
  OBJECT_VALUE_UNDEFINED: '#569cd6',
  OBJECT_VALUE_SYMBOL: '#569cd6',
  OBJECT_VALUE_FUNCTION: '#dcdcaa',
  OBJECT_VALUE_REGEXP: '#d16969',
  OBJECT_VALUE_DATE: '#c586c0',
  OBJECT_PROPERTY: '#9cdcfe',
  OBJECT_STRING_QUOTE: '#d4d4d4',
  OBJECT_KEYQUOTE: '#d4d4d4',
}

const SocketTest: FC<IPopUp> = ({ open, closeModal }) => {
  const socketDataTest = useSelector(getSocketTestDataSelector)

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='modal'>
        <div className='popup'>
          <div className='SocketTest'>
            <div className='SocketTest-block'>
              <div className='SocketTest-header'>Test Socket</div>
              {/* Обратите внимание: класс на кнопке для переопределения стилей */}
              <button className='SocketTest-closeBtn' onClick={closeModal}>
                Close
              </button>

              <div className='SocketTest-messages'>
                {Array.isArray(socketDataTest) && socketDataTest.length > 0 ? (
                  // Создаем копию массива и переворачиваем его
                  socketDataTest.slice().map((data: any, index: number) => (
                    <div key={index} className={`SocketTest-message ${data.direction === 'outgoing' ? 'outgoing' : 'incoming'}`}>
                      {/* Нумерация сообщения */}
                      <div className='SocketTest-messageNumber'>{index + 1}.</div>
                      {/*// @ts-ignore*/}
                      <ObjectInspector data={data} theme={'chromeLight'} />
                    </div>
                  ))
                ) : (
                  <p>No socket data available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default SocketTest
