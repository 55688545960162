import './scss/index.scss'

const NoResults = () => {
  return (
    <div className='no-results'>
      <img src={require('../../images/img/no-results_1.png')} alt='No results' />
      <h2>No results found</h2>
    </div>
  )
}

export default NoResults
