import React from 'react'
import Logo from '../../Logo/Logo'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { photoViewUserMobile1 } from '../../../../UI/PhotoViewUserMobile2/PhotoViewUserMobile1'
import Notifications from '../../../../elements/Notifications/Notifications'
import Language from '../../../../UI/Language/Language'
import { photoViewUserMobile } from '../../../../UI/PhotoViewUserMobile/PhotoViewUserMobile'
import { useTranslation } from 'react-i18next'

import { getAccount } from '../../../../store/reducer/accountController/selectors'
import { useSelector } from 'react-redux'

const HeaderVideo = ({ isMenuOpen, setIsMenuOpen, data, dataUser, handleMenuToggle }: any) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const dataAmount = useSelector(getAccount)

  return (
    <>
      <header className='header HeaderVideo' role='banner'>
        <div className='header-menu_left'>
          <Logo />
        </div>
        <ul>
          <li>
            <Notifications />
          </li>
          <li>
            <Language />
          </li>
          <li>
            <div className='box'>
              <div className='box-content_box'>
                <div className='box-content_img'>
                  <div className='img-box'>{photoViewUserMobile(data?.client?.photoId)}</div>
                </div>
                <div className='box-content_left'>
                  <p className='name'>{dataUser?.name}</p>
                  <div className='box-content_box'>
                    <p className='header-menu_right-text'>{t('profile.coins')}:</p>
                    <p>{dataAmount?.coins}</p>
                  </div>
                  <div className='box-content_box'>
                    <p className='header-menu_right-text'>{t('profile.messages')}:</p>
                    <p>{dataAmount?.messages}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </header>
    </>
  )
}

export default HeaderVideo
