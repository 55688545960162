import React, { FC, useCallback, useEffect, useState } from 'react'
import './scss/index.scss'
import RenderGiftIconAndName from '../../../../utils/RenderGiftIconAndName/RenderGiftIconAndName'
import ButtonInfo from '../../../ButtonInfo/ButtonInfo'
// @ts-ignore
import Popup from 'reactjs-popup'
import { SvgClosePopUP } from '../../../../images/svg'
import { useSelector } from 'react-redux'
import { getPopUpData } from 'store/reducer/popUp/selectors'
import { useGiftSendMutation } from 'store/apiMain/mainApi'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import useLockBodyScroll from '../../../../hooks/useLockBodyScroll'
import { selectRole } from 'store/reducer/token/selector'
import useSoundPlayer from '../../../../hooks/useSoundPlayer'

interface Props {
  closeModel: () => void
  open: boolean
}

type Data = {
  modelId: string
  status: string
  giftSendRequest: { giftId: number }
  price: number
}

export const PopUpPresent: FC<Props> = ({ open, closeModel }) => {
  const [isShow, setIsShow] = useState<boolean>(false)
  const payloadGift = (useSelector(getPopUpData)?.data || {}) as Data
  const role = useSelector(selectRole)
  const { modelId, giftSendRequest, price } = payloadGift
  const [addGift, { isSuccess }] = useGiftSendMutation()

  const { playSound, stopAllSounds } = useSoundPlayer(false)

  useEffect(() => {
    if (isSuccess) {
      closeModel()
      toastSuccess('Gift sent successfully')
      playSound('gift')
    }
  }, [isSuccess])

  useEffect(() => {
    return () => {
      //closeModel()
    }
  }, [])

  const handleSendGift = useCallback(() => {
    if (giftSendRequest?.giftId && modelId) {
      if (role === 'MODEL') {
        toastSuccess('Себе нельзя отпарвлять подароки! :)')
      } else {
        addGift(payloadGift)
      }
    }
    // Assuming addGift sends the gift using an API mutation
  }, [payloadGift])
  useLockBodyScroll(open)
  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='modal'>
        <div className='popup'>
          {open && (
            <div className={'pop-up__container'}>
              <div className={`pop-up__container-info`}>
                {payloadGift.status !== 'FRIENDS' ? (
                  <ButtonInfo
                    text={'Вы не являетесь друзьями, но подарком вы привлечёте внимание модели, и в ближайшее свободное время вас добавят в друзья.'}
                    onClick={() => setIsShow(true)}
                  />
                ) : null}
                <button className={`close`} onClick={closeModel}>
                  <SvgClosePopUP />
                </button>
              </div>
              <header className={'pop-up__header'}>
                Отправить
                <br /> подарок?
              </header>
              {/*<p className={`pop-up__container-text__info`}>*/}
              {/*  Вы не являетесь друзьями, но подарком вы привлечёте внимание модели, и в ближайшее свободное время вас добавят в друзья.*/}
              {/*</p>*/}
              <div className={`pop-up__container__box`}>
                <RenderGiftIconAndName index={(giftSendRequest?.giftId || 0) + 1} />
                <div className={`pop-up__container__box-price`}>
                  <p> {price} coins</p>
                </div>
              </div>
              <div className={`pop-up__container-btn`}>
                <button className={`btn-action btn__no`} onClick={closeModel}>
                  Нет
                </button>
                <button className={`btn-action btn__yes`} onClick={handleSendGift}>
                  Да
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Popup>
  )
}
