import React, { FC } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { IPopUp } from '../../types'
import Girl from '../../../../Components/User/Girl/Girl'
import { useSelector } from 'react-redux'
import { getPopUpData } from 'store/reducer/popUp/selectors'
import './scss/index.scss'
import { SvGArrowBlackLeft } from '../../../../images/svg'

const GirlPopUp: FC<IPopUp> = ({ open, closeModal }) => {
  const selectedDataPopUp = useSelector(getPopUpData)?.data
  return (
    <>
      <Popup open={open} onClose={() => closeModal()} closeOnDocumentClick={false} closeOnEscape={false} modal>
        <div className='modal'>
          <div className='popup'>
            <div className='GirlPopUp'>
              <div
                className='GirlPopUp-close'
                onClick={() => {
                  closeModal()
                  debugger
                }}
              >
                <SvGArrowBlackLeft />
                <p>Back</p>
              </div>
              <Girl modelIdForVideoChat={selectedDataPopUp?.modelId} />
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default GirlPopUp
