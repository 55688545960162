import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ModelFullDto } from 'store/apiMain/mainApi'
import { SvgAddMessages, SvGArrowBlackLeft, SvgBlock, SvgDelete, SvgHeart2, SvgMinus, SvgPlusAdd } from 'images/svg'
import './scss/index.scss'
import Slider from '../../../UI/Slider/Slider'
import TooltipGift from '../../../UI/Tooltip/GiftTooltip/GiftTooltip'
import { useDispatch, useSelector } from 'react-redux'
import { sendMessages } from 'store/reducer/messages/reducer'
import { selectUserMe } from 'store/reducer/token/selector'
import { BoxList } from './BoxList'
import { GirlInfo } from './GirlInfo'
import { selectLanguageData } from 'store/reducer/language/selector'
import ButtonInfo from '../../../UI/ButtonInfo/ButtonInfo'

type GirlProps = {
  data: ModelFullDto
  addFriends?: any
  modelIdForVideoChat?: any
  addFriendsRequest?: any
  addFavorites?: any
  addDelete?: any
  role: 'model' | 'client'
}

const Item: React.FC<GirlProps> = ({ data, addFriends, addFriendsRequest, addFavorites, addDelete, role, modelIdForVideoChat }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentYear = new Date().getFullYear()
  // @ts-ignore
  const age = currentYear - data?.model?.birthYear
  const userMe = useSelector(selectUserMe)

  const renderButton = (onClick: () => void, label: string, icon = <SvgPlusAdd />) => (
    <button onClick={onClick} className='btn-relationship'>
      <span>{label}</span>
      <div className='btn-icon'>{icon}</div>
    </button>
  )

  const language = useSelector(selectLanguageData)

  const renderButtonState = () => {
    const { status, initiatedBy } = data?.relationshipWithCurrentUser || {}
    switch (status) {
      case 'FRIENDS':
        return (
          <>
            {renderButton(
              () => {
                // @ts-ignore
                if (data?.userData?.user?.id && userMe?.userData?.user?.id) {
                  const obg = {
                    recipientId: data?.userData?.user?.id,
                    // @ts-ignore
                    senderId: userMe?.userData?.user?.id,
                  }
                  dispatch(sendMessages(obg))
                  navigate('/messages')
                }
              },
              `Send message`,
              <SvgAddMessages />,
            )}

            {renderButton(addDelete, 'Remove from friends', <SvgDelete />)}
          </>
        )
      case 'BLOCKED':
        if (initiatedBy !== 'MODEL') {
          return <>{renderButton(addDelete, 'Разблокировать', <SvgBlock />)}</>
        }
        return null
      case 'FRIEND_REQUEST':
        if (initiatedBy === 'MODEL') {
          return (
            <>
              {renderButton(addFriendsRequest, 'Принять запрос')}
              {renderButton(addDelete, 'Отклонить запрос')}
            </>
          )
        }
        if (initiatedBy === 'CLIENT') {
          return <>{renderButton(addDelete, 'Отменить запрос', <SvgMinus />)}</>
        }
        break
      default:
        return <>{renderButton(addFriends, 'Add to friends')}</>
    }
  }

  const renderFavoriteState = () => {
    if (data?.relationshipWithCurrentUser?.isClientFavorite) {
      return (
        <button
          onClick={() => {
            addFavorites()
          }}
          className='btn-relationship btn-favorites'
        >
          <span>Remove from favorites</span>
          <div className='btn-icon'>
            <SvgHeart2 />
          </div>
        </button>
      )
    } else {
      return (
        <button
          onClick={() => {
            addFavorites()
          }}
          className='btn-relationship btn-favorites'
        >
          <span>Add to favorites</span>
          <div className='btn-icon'>
            <SvgHeart2 />
          </div>
        </button>
      )
    }
  }

  const relationshipStatusButtons = renderButtonState()
  const favoriteStatusButton = renderFavoriteState()

  return (
    <>
      <div className='girl-profile'>
        <main className='account__main'>
          <div className='container'>
            {role === 'model' || modelIdForVideoChat ? null : (
              <div
                onClick={() => {
                  navigate(-1)
                }}
                className='account__nav'
              >
                <SvGArrowBlackLeft />
                <p>Back</p>
              </div>
            )}
            <div className='account__inner'>
              <div className='account__slider'>
                <Slider idModel={data?.model?.id} photoArray={data?.photos ?? []} videoArray={data?.videos ?? []} />
                <div className='account-slider__main-gradient'></div>
              </div>
              <div className='account__content'>
                <div className='flex'>
                  <div className='flex-column'>
                    <div className='box'>
                      {modelIdForVideoChat ? null : (
                        <>
                          {relationshipStatusButtons}
                          {favoriteStatusButton}
                        </>
                      )}

                      <div className='right'>
                        <p className='right-box'>
                          The first 3 minutes — {3 * (data?.model?.minutePrice ?? 1)} coins, then {data?.model?.minutePrice} coins per minute <br />
                        </p>
                        <ButtonInfo
                          text={
                            'Наша система тарификации работает следующим образом: первые 3 минуты пребывания стоят 30 монет — это минимальная продолжительность и стоимость. После этих 3 минут каждая последующая минута будет стоить 10 монет.'
                          }
                        />
                      </div>
                    </div>
                    <TooltipGift id={data.userData?.user?.id} isPopUp={true} status={data?.relationshipWithCurrentUser?.status} />
                  </div>
                  <BoxList data={data} age={age} />
                </div>
                <GirlInfo info={data?.model?.selfInformation ?? ''} language={language.backendName} />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Item
