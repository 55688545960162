const getSystemMessageText = (messageType, role, t) => {
  switch (messageType) {
    case 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_CLIENT':
      return role === 'CLIENT'
        ? 'Вы отправили заявку в друзья! После того как заявку примут, можно будет общаться.'
        : 'Вам отправили заявку в друзья! После того как вы примете заявку, можно будет общаться.'

    case 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_MODEL':
      return role === 'CLIENT'
        ? 'Вам отправили заявку в друзья! После того как вы примете заявку, можно будет общаться.'
        : 'Вы отправили заявку в друзья! После того как заявку примут, можно будет общаться.'

    case 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_CLIENT':
      return role === 'CLIENT' ? t('message.deletedFriendRequest') : t('message.clientDeletedFriendRequest')

    case 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_MODEL':
      return role === 'CLIENT' ? t('message.modelDeletedFriendReq') : t('message.deletedFriendRequest')

    case 'SYSTEM_FRIEND_REMOVED_BY_CLIENT':
      return role === 'CLIENT' ? t('message.deletedFriend') : t('message.clientRemoveFromFriendsList')

    case 'SYSTEM_FRIEND_REMOVED_BY_MODEL':
      return role === 'CLIENT' ? t('message.modelDeletedFriendReq') : t('message.deletedFriend')

    case 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_CLIENT':
      return role === 'CLIENT' ? t('message.youAcceptedFriendReq') : t('message.clientAcceptedFriendReq')

    case 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_MODEL':
      return role === 'CLIENT' ? 'Модель принял(а) вашу заявку в друзья! Теперь можно общаться.' : t('message.youAcceptedFriendReq')

    case 'SYSTEM_BLOCKED_BY_MODEL':
      return role === 'CLIENT' ? 'Модель заблокировал(а) вас.' : 'Вы заблокировали клиента.'

    case 'SYSTEM_UNBLOCKED_BY_MODEL':
      return role === 'CLIENT' ? 'Модель разблокировал(а) вас.' : 'Вы разблокировали клиента.'

    case 'SYSTEM_BLOCKED_BY_CLIENT':
      return role === 'CLIENT' ? 'Вы заблокировали модель.' : 'Клиент заблокировал вас.'

    case 'SYSTEM_UNBLOCKED_BY_CLIENT':
      return role === 'CLIENT' ? 'Вы разблокировали модель.' : 'Клиент разблокировал вас.'

    case 'SYSTEM_PRIVATE_CHAT_STAFF_ASSIGNED':
      return 'Чат предназначен персоналу.'

    case 'SYSTEM_PRIVATE_CHAT_DISABLED':
      return 'Чат отключен.'

    case 'SYSTEM_USER_WAS_DELETED':
      return 'Пользователь был удалён из системы.'

    case 'SYSTEM_PRIVATE_CHAT_CLIENT_ASSIGNED':
      return 'Чат предназначен клиенту.'

    case 'SYSTEM_PRIVATE_CHAT_MODEL_ASSIGNED':
      return 'Чат предназначен модели.'

    case 'SYSTEM_CALL_STARTED':
      return 'Звонок начался.'

    case 'SYSTEM_CALL_FINISHED':
      return 'Звонок завершён.'

    case 'SYSTEM_CALL_INITIATED':
      return 'Звонок инициирован.'

    case 'SYSTEM_CALL_CANCELLED':
      return 'Звонок отменён.'

    default:
      // Если вдруг какой-то системный тип не попал в switch:
      return 'Системное сообщение.'
  }
}
export default getSystemMessageText
