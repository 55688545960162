import React from 'react'
import './scss/index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { popUpOpen, setPopUpData } from 'store/reducer/popUp/reducer'
import { selectRole } from '../../../store/reducer/token/selector'
import {
  MessageReadAllApiArg,
  PrivateChatDisableByIdApiArg,
  useMessageReadAllMutation,
  usePrivateChatDisableByIdMutation,
} from 'store/apiMain/mainApi'
import { getSelectorMessagesSelectedId } from '../../../store/reducer/messages/selectors'

const ActionUserTooltip = () => {
  const dispatch = useDispatch()
  const role = useSelector(selectRole)
  const [closeChat] = usePrivateChatDisableByIdMutation()
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const [readAllMessages] = useMessageReadAllMutation()
  return (
    <div className='ActionUserTooltips'>
      <div className='tooltips'>
        <div className='tooltips-block'>
          <div className='tooltips-block_box'>
            <ul>
              <li
                onClick={() => {
                  dispatch(popUpOpen('complaint'))
                }}
              >
                Send a complaint
              </li>
              <li
                onClick={() => {
                  dispatch(popUpOpen('complaint'))
                  dispatch(setPopUpData({ data: true }))
                }}
              >
                Block user
              </li>
              {role === 'STAFF' ? (
                <>
                  <li
                    onClick={() => {
                      if (messagesSelectedId) {
                        const payload: PrivateChatDisableByIdApiArg = {
                          privateChatId: messagesSelectedId,
                        }
                        closeChat(payload)
                      }
                    }}
                  >
                    Close chat
                  </li>
                </>
              ) : null}
              <li
                onClick={() => {
                  if (messagesSelectedId) {
                    const payload: MessageReadAllApiArg = {
                      privateChatId: messagesSelectedId,
                    }
                    readAllMessages(payload)
                  }
                }}
              >
                Прочитать все сообщения
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionUserTooltip
