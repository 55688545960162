import React, { useState, useEffect, useRef } from 'react'
import TimeStartStop from './Button/TimeStartStop'
import Coin from './Button/Coin/Coin'
import Gift from './Button/Gift/Gift'
import Language from '../../../../../../UI/Language/Language'
import { useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import { ChatIcon } from '@livekit/components-react'

import useSoundPlayer from '../../../../../../hooks/useSoundPlayer'
import Logo from '../../../../Logo/Logo'
import Actions from './Button/Actions/Actions'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { toastInfo } from '../../../../../../elements/Notification/Notification'
import Messages from '../../../../../User/Messages/Messages'
import Chat from '../../../../../User/Messages/RightBlock/Chat/Chat'

const CustomButton: React.FC<any> = () => {
  const role = useSelector(selectRole)
  const videoChat = useSelector(selectVideoChatObg)?.room
  const disabled = videoChat?.participantRole === undefined || videoChat?.ownerUserRole === undefined

  const [chatView, setChatView] = useState(false)
  const { playSound, stopAllSounds } = useSoundPlayer(false)
  useEffect(() => {
    stopAllSounds()
  }, [])
  return (
    <div className='buttons-videoCall'>
      <div className='lk-control-bar'>
        <div className='lk-button-group'>
          <div className='lk-button-group-menu'>
            <button className='lk-button lk-chat-toggle'>
              <Language dark={true} />
            </button>
          </div>
        </div>
        <Actions />
        {/*{role === 'CLIENT' ? null : <TimeStartStop />}*/}
        <TimeStartStop />
        {role === 'CLIENT' ? <Coin /> : null}
        {role === 'CLIENT' ? <Gift /> : null}
        <button
          className='lk-button'
          onClick={() => {
            if (disabled) {
              toastInfo('Нет клиента!')
            } else {
              setChatView(!chatView)
            }
          }}
        >
          {<ChatIcon />}
        </button>
        <>
          {chatView ? (
            <div className='videoChat-text'>
              <Chat />
            </div>
          ) : null}
        </>
      </div>
    </div>
  )
}

export default CustomButton
