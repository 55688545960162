import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import {
  useMessageCreateMutation,
  useMessageGetAllQuery,
  useMessageReadByIdsMutation,
  useMessageWithVideoCreateMutation,
  useUserGetFullMeQuery,
} from 'store/apiMain/mainApi'
import MessageType from '../MessagesType/MessageType'
import { getSelectorMessagesSelectedId, getSelectorSetMessagePrivetChats } from '../../../../../store/reducer/messages/selectors'
import {
  addMessagePrivetChat,
  addMessagePrivetChatMessagesError,
  getMessagePrivetChat,
  updateScrollPositionPrivetChat,
} from '../../../../../store/reducer/messages/reducer'
import './scss/index.scss'
import { toastInfo } from '../../../../../elements/Notification/Notification'
import useWebSocket from '../../../../../socket/useWebSocket'
import getMessageAttributesByUserId, { MessageAttributes } from '../../../../../utils/getMessageAttributesByUserId'
import imgStaff from '../../../../../images/img/staff3.png'
import { photoViewUser } from '../../../../../UI/PhotoViewUser/PhotoViewUser'
import { selectRole } from '../../../../../store/reducer/token/selector'
import { selectClients, selectModels, selectStaffs } from 'store/reducer/user/selectors'
import { t } from 'i18next'
import { SvgChatDown, SvgSendMessage } from '../../../../../images/svg'
import { useLocation } from 'react-router-dom'

const MessagesBlock = () => {
  const dispatch = useDispatch()

  const { sendChatTyping } = useWebSocket()

  const selectorSetMessagePrivetChats = useSelector(getSelectorSetMessagePrivetChats)
  const messagePrivetChatRef = useRef(selectorSetMessagePrivetChats)
  useEffect(() => {
    messagePrivetChatRef.current = selectorSetMessagePrivetChats
  }, [selectorSetMessagePrivetChats])
  const { data: userMe } = useUserGetFullMeQuery()
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const [readIdsArray, setReadIdsArray] = useState<string[]>([])
  const [scrollTop, setScrollTop] = useState(0)
  const [createMessage, { isSuccess, isLoading: isLoadingCreateMessage, isError: isErrorCreateMessage }] = useMessageCreateMutation()
  const [createMessageVideo, { isSuccess: isSuccessVideo, isLoading: isLoadingCreateMessageVideo, isError: isErrorCreateMessageVideo }] =
    useMessageWithVideoCreateMutation()
  const [markAsRead] = useMessageReadByIdsMutation()
  const location = useLocation() // Получаем текущий путь
  const [hasRoom, setHasRoom] = useState(false)

  useEffect(() => {
    // Проверяем наличие слова "room" в текущем пути
    if (location.pathname.includes('room')) {
      setHasRoom(true)
    } else {
      setHasRoom(false)
    }
  }, [location]) // Срабатывает при изменении пути
  useEffect(() => {
    if (isErrorCreateMessage) {
      dispatch(
        addMessagePrivetChatMessagesError({
          privateChatId: messagesSelectedIdRef.current as string,
        }),
      )
    }
  }, [isErrorCreateMessage])

  useEffect(() => {
    if (messageWindowRef.current) {
      messageWindowRef.current.scrollTop = messageWindowRef.current.scrollHeight
    }
  }, [isLoadingCreateMessage])
  const messageWindowRef = useRef<HTMLDivElement | null>(null)

  const handleScroll = () => {
    if (messageWindowRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageWindowRef.current
      const threshold = scrollHeight - clientHeight
      setScrollTop(scrollTop)
      const payload = {
        messagesSelectedId: messagesSelectedIdRef.current,
        scrollTop,
      }
      dispatch(updateScrollPositionPrivetChat(payload))
      if (scrollTop <= -threshold + 50) {
        setPayload(prevPayload => ({
          ...prevPayload,
          token: messageTokenRef.current,
        }))
      }
    }
  }

  useEffect(() => {
    const messageWindow = messageWindowRef.current
    if (messageWindow) {
      messageWindow.addEventListener('scroll', handleScroll)
      messageWindow.scrollTop = messageWindow.scrollHeight
    }
    return () => {
      if (messageWindow) {
        messageWindow.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const chatIndex = selectorSetMessagePrivetChats?.content.findIndex(chat => chat.privateChat.id === messagesSelectedId)

  // @ts-ignore
  const messageToken =
    // @ts-ignore
    selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat?.messages?.token
  // @ts-ignore
  const messageScrollTop =
    // @ts-ignore
    selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat?.messages?.scrollTop
  const messagesSelectedIdRef = useRef(messagesSelectedId)
  const messagesSelectedPrivateChatRef = useRef(selectorSetMessagePrivetChats)
  const messageTokenRef = useRef(messageToken)

  useEffect(() => {
    messagesSelectedIdRef.current = messagesSelectedId
  }, [messagesSelectedId])

  useEffect(() => {
    messageTokenRef.current = messageToken
  }, [messageToken])

  const [payload, setPayload] = useState({
    privateChatId: messagesSelectedIdRef.current,
    token: undefined,
    pageSize: 10,
  })

  useEffect(() => {
    if (messagesSelectedId !== null) {
      setPayload({
        privateChatId: messagesSelectedId,
        token: undefined,
        pageSize: 10,
      })
    }
  }, [messagesSelectedId])

  // @ts-ignore
  const { data, isFetching, isLoading } = useMessageGetAllQuery(payload, {
    skip: !payload.privateChatId || payload.privateChatId === 'CREATE_NEW_CHAT_STAFF',
  })

  useEffect(() => {
    // @ts-ignore
    if (
      data &&
      data.content.length &&
      // @ts-ignore
      !selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat?.messages?.content
    ) {
      const payload = {
        ...data,
      }
      // @ts-ignore
      dispatch(getMessagePrivetChat(payload))
      // @ts-ignore
    } else if (
      data &&
      data.content.length &&
      // @ts-ignore
      selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat?.messages?.content
    ) {
      // Предположим, у нас есть функция для получения существующих данных из состояния
      // @ts-ignore
      const existingMessages =
        // @ts-ignore
        selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat?.messages?.content

      // Функция для проверки уникальности сообщений
      const filterUniqueMessages = (newMessages: any[], existingMessages: any[]) => {
        const existingIds = new Set(existingMessages.map(message => message.id))
        return newMessages.filter(message => !existingIds.has(message.id))
      }

      // Фильтрация уникальных сообщений
      const uniqueMessages = filterUniqueMessages(data.content, existingMessages)

      if (uniqueMessages.length > 0) {
        const payload = {
          ...data,
          content: uniqueMessages,
        }
        // @ts-ignore
        dispatch(getMessagePrivetChat(payload))
      }
    }
  }, [data, dispatch])

  useEffect(() => {
    if (readIdsArray.length > 0 && messagesSelectedId !== null) {
      const timeout = setTimeout(() => {
        markAsRead({
          privateChatId: messagesSelectedId,
          messageIds: [...readIdsArray],
        })
        setReadIdsArray([])
      }, 10)

      return () => clearTimeout(timeout)
    }
  }, [readIdsArray, markAsRead, messagesSelectedId])

  useEffect(() => {
    if (messageWindowRef.current && messageScrollTop !== undefined) {
      messageWindowRef.current.scrollTop = messageScrollTop
    }
  }, [messageScrollTop])

  const changeArrayReadIds = (idRead: string) => {
    setReadIdsArray(prevReadIdsArray => [...prevReadIdsArray, idRead])
  }

  const [width, setWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // Удаляем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [height, setHeight] = useState('auto')
  const [headerChatHeaderRightBlockHeight, setHeaderChatHeaderRightBlockHeight] = useState(0)
  useEffect(() => {
    // учитываем высоту header который в righrBlock чтобы правильно высчитать отсуп от отпарвки сообещия до самого сообщения
    const headerChatHeaderRightBlockWidth = document.querySelector('.chat-header') as HTMLElement | null
    if (headerChatHeaderRightBlockWidth) {
      setHeaderChatHeaderRightBlockHeight(headerChatHeaderRightBlockWidth.offsetHeight)
    }
    const handleResize = () => {
      if (headerChatHeaderRightBlockWidth) {
        setHeaderChatHeaderRightBlockHeight(headerChatHeaderRightBlockWidth.offsetHeight)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerHeight, headerChatHeaderRightBlockHeight])
  const updateHeight = () => {
    const screenHeight = window.innerHeight
    let newHeight

    if (width < 768) {
      if (screenHeight < 320) {
        newHeight = 150 + 30
      } else {
        newHeight = 150 + (screenHeight - 320) + 30
      }
    } else {
      if (screenHeight < 320) {
        newHeight = 150
      } else {
        newHeight = 150 + (screenHeight - 320)
      }
    }
    if (headerChatHeaderRightBlockHeight > 80) {
      newHeight -= 60
    }

    setHeight(`${newHeight}px`)
  }

  useEffect(() => {
    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [headerChatHeaderRightBlockHeight])

  const [isSending, setIsSending] = useState<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleInputChange = () => {
    if (isSending) return

    const payload = {
      privateChatId: messagesSelectedIdRef.current,
      // @ts-ignore
      targetUserId:
        // @ts-ignore
        messagePrivetChatRef.current?.content[chatIndex]?.privateChat?.matchingContent?.user?.id,
    }
    // @ts-ignore
    sendChatTyping(payload)

    setIsSending(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setIsSending(false)
    }, 2800)
  }

  const role = useSelector(selectRole)
  const dataClient = useSelector(selectClients)
  const dataModel = useSelector(selectModels)
  const dataStaff = useSelector(selectStaffs)
  // @ts-ignore
  const privatChatSelect =
    // @ts-ignore
    selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat

  const messageAttributesByUserId = useCallback(() => {
    if (role && privatChatSelect) {
      return getMessageAttributesByUserId(
        userMe,
        privatChatSelect,
        _ => <img src={imgStaff} alt='' />,
        model => (model?.file?.url ? <img src={model?.file?.url} alt='' /> : null),

        // @ts-ignore
        client => photoViewUser(client?.photoId!),
        dataClient,
        dataModel,
        dataStaff?.content,
      )
    } else {
      return new Map<string, MessageAttributes>()
    }
  }, [role, dataClient, dataModel, dataStaff, privatChatSelect])

  return (
    <div style={{ height }} ref={messageWindowRef} className='message-user__chat'>
      {selectorSetMessagePrivetChats?.content
        .filter(e => e.privateChat.id === messagesSelectedId)
        .map(e => {
          const messages = e.privateChat.messages?.content

          return messages?.map((item, index) => (
            <MessageType
              key={item.id}
              messageAttributesByUserId={messageAttributesByUserId()}
              message={item}
              changeArrayReadIds={changeArrayReadIds}
              lastMessage={index === 0}
            />
          ))
        })}
      <div className='chat-form__container'>
        <Formik
          initialValues={{ messageText: '' }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (!values.messageText.trim()) {
              setSubmitting(false)
              return
            }

            if (messagesSelectedIdRef.current !== null) {
              // @ts-ignore
              const privetChatSelected =
                // @ts-ignore
                selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat
              if (privetChatSelected?.approvedByClient && privetChatSelected?.approvedByModel) {
                const payload = {
                  privateChatId: messagesSelectedIdRef.current as string,
                  messageCreateRequest: {
                    body: values.messageText,
                  },
                }
                const privateChatId = messagesSelectedIdRef.current as string
                const clientId = userMe?.userData?.user?.id

                const createdAt = new Date()
                // @ts-ignore
                const senderRole =
                  // @ts-ignore
                  messagePrivetChatRef.current?.content[chatIndex]?.privateChat?.matchingContent?.user?.role
                // @ts-ignore
                const senderId =
                  // @ts-ignore
                  messagePrivetChatRef.current?.content[chatIndex]?.privateChat?.matchingContent?.user?.id
                const payloadLocal = {
                  body: {
                    id: 'pending',
                    privateChatId: privateChatId,
                    privateChat: {
                      id: privateChatId,
                      clientId: senderRole === 'CLIENT' ? senderId : undefined,
                      modelId: senderRole === 'MODEL' ? senderId : undefined,
                      staffId: senderRole === 'STAFF' ? senderId : undefined,
                      approvedByClient: true,
                      approvedByStaff: true,
                      approvedByModel: true,
                      isDisabled: false,
                      createdAt: createdAt,
                      type: 'GENERAL',
                    },
                    createdAt: createdAt,
                    type: 'MESSAGE_CREATED',
                    messageId: 'pending',
                    message: {
                      id: 'pending',
                      createdAt: createdAt,
                      createdBy: clientId,
                      privateChatId: privateChatId,
                      type: 'GENERAL',
                      body: values.messageText,
                      senderRole: role,
                      clientId: senderRole === 'CLIENT' ? senderRole : undefined,
                      modelId: senderRole === 'MODEL' ? senderRole : undefined,
                      staffId: senderRole === 'STAFF' ? senderRole : undefined,
                      isReadByClient: role === 'CLIENT',
                      isReadByModel: role === 'MODEL',
                      isReadByStaff: role === 'STAFF',
                    },
                    initiatorUserId: clientId,
                  },
                  timestamp: createdAt,
                  subType: 'CHAT',
                  type: 'OTHER',
                  role: role,
                }

                // @ts-ignore
                dispatch(addMessagePrivetChat(payloadLocal))
                if (hasRoom) {
                  await createMessageVideo(payload)
                } else {
                  await createMessage(payload)
                }
              } else {
                if (privetChatSelected.type === 'SUPPORT') {
                  const payload = {
                    privateChatId: messagesSelectedIdRef.current as string,
                    messageCreateRequest: {
                      body: values.messageText,
                    },
                  }
                  if (hasRoom) {
                    await createMessageVideo(payload)
                  } else {
                    await createMessageVideo(payload)
                  }
                } else {
                  toastInfo(t('message.youNotAdd'))
                }
              }
            } else {
              // Handle the case where messagesSelectedIdRef.current is null
              console.error('messagesSelectedIdRef.current is null')
              // Optionally, provide some user feedback or other handling here
            }
            resetForm()
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, handleChange }) => (
            <Form className='chat-form'>
              <div className='chat-form__inner'>
                <Field
                  type='text'
                  name='messageText'
                  placeholder={t('message.addText')}
                  onChange={(e: any) => {
                    handleInputChange()
                    handleChange(e)
                  }}
                />
                <div className='input__volume'></div>
                <button type='submit' disabled={isSubmitting}>
                  <SvgSendMessage />
                </button>
                <div style={{ opacity: (messageScrollTop || 0) > -100 ? 0 : 1 }} className='chat-form__clear-scroll'>
                  <button
                    onClick={() => {
                      const payload = {
                        messagesSelectedId: messagesSelectedIdRef.current,
                        scrollTop: 0,
                      }
                      dispatch(updateScrollPositionPrivetChat(payload))
                    }}
                    type='button'
                  >
                    <SvgChatDown />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default MessagesBlock
