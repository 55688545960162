import React from 'react'
import './scss/index.scss'
import RenderGiftIconAndName from '../../../../utils/RenderGiftIconAndName/RenderGiftIconAndName'
import dataFormatMessages from '../../../../utils/dataFormatMessages'
import { MessageResponse } from 'store/apiMain/mainApi'

interface IProps {
  message: MessageResponse
  userMe: any
  role: 'CLIENT' | 'MODEL' | 'STAFF' | 'UNKNOWN' | undefined
  giftId: number
}

const GiftMessages: React.FC<IProps> = ({ giftId, message, role }) => {
  return (
    <div className='gift-box'>
      <div className='giftSvg'>
        <div className='giftSvg-block'>
          <RenderGiftIconAndName index={giftId} name={false} />
        </div>
      </div>
      <div className='gift--ribbon-wrapper'>
        <div className='gift--ribbon gift--ribbon__left'>
          <div className='gift--ribbon-part part__big'></div>
          <div className='gift--ribbon-part part__small'></div>
        </div>
        <div className='gift--ribbon-node__wrapper'>
          <div className='gift--ribbon-node__big'></div>
          <div className='gift--ribbon-node__small'></div>
        </div>
        <div className='gift--ribbon gift--ribbon__right'>
          <div className='gift--ribbon-part part__big'></div>
          <div className='gift--ribbon-part part__small'></div>
        </div>
      </div>
      <div className='gift--body'>
        <div className='gift--cover'>
          <p>{role === 'CLIENT' ? 'Вы отправили подарок!' : 'Вам отправили подарок!'}</p>
        </div>
        <div className='gift--strip'></div>
        <div className='gift--bottom'>
          <p className='time'>{dataFormatMessages(message.createdAt)}</p>
        </div>
      </div>
    </div>
  )
}

export default GiftMessages
