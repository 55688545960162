import './scss/index.scss'
import { useTranslation } from 'react-i18next' // Import useTranslation

const Tabs = ({ activeTab, setActiveTab }: any) => {
  const { t } = useTranslation() // Initialize the translation hook

  return (
    <div className='friends__tabs'>
      <div className={`friends__tabs-item ${activeTab === undefined ? 'active' : ''}`} onClick={() => setActiveTab(undefined)}>
        <p>All</p>
      </div>
      <div className={`friends__tabs-item ${activeTab === 'Sent' ? 'active' : ''}`} onClick={() => setActiveTab('Sent')}>
        <p>Sent</p>
      </div>
      <div className={`friends__tabs-item ${activeTab === 'Received' ? 'active' : ''}`} onClick={() => setActiveTab('Received')}>
        <p>Received</p>
      </div>
    </div>
  )
}

export default Tabs
