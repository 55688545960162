import React, { FC, useEffect, useState, useCallback } from 'react'
import { IPopUp } from '../types'
import './scss/index.scss'
import Boom from './Boom/Boom'
import img1 from '../../../images/img/gifts/1.png'
import img2 from '../../../images/img/gifts/2.png'
import img3 from '../../../images/img/gifts/3.png'
import img4 from '../../../images/img/gifts/4.png'
import img5 from '../../../images/img/gifts/5.png'
import img6 from '../../../images/img/gifts/6.png'
import { useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import { getPopUpData } from 'store/reducer/popUp/selectors'

const giftIcons = [img1, img2, img3, img4, img5, img6]
const giftIconsName = ['Бриллиант', 'Кольцо', 'Машина', 'Роза', 'Клубника', 'Замок']

// Константы для задержек анимации (ms)
const WOBBLE_DURATION = 1500
const AUTO_CLOSE_DURATION = 5000
const FADE_OUT_DURATION = 500

const GiftAnimations: FC<IPopUp> = ({ open, closeModal }) => {
  const giftId = useSelector(getPopUpData)?.data
  const role = useSelector(selectRole)
  const [animations, setAnimations] = useState({
    fade: '', // отвечает за появление/исчезновение попапа
    gift: '', // отвечает за анимацию главного контейнера подарка
    giftCover: '', // отвечает за анимацию крышки подарка
    giftContainer: '', // отвечает за анимацию самого коробка внутри
    card: '', // отвечает за анимацию карточки
  })

  // Универсальный метод для смены стейтов-анимаций
  const updateAnimations = useCallback((newValues: Partial<typeof animations>) => {
    setAnimations(prev => ({ ...prev, ...newValues }))
  }, [])

  useEffect(() => {
    // Храним все таймеры в массиве, чтобы в cleanup их очистить
    const timers: NodeJS.Timeout[] = []

    if (open) {
      // Шаг 1: показываем попап, даём эффект fade-in
      updateAnimations({ fade: 'fade-in' })

      // Шаг 2: запускаем wobble
      updateAnimations({ gift: 'animate__wobble', giftCover: 'animate__wobble' })

      // Через WOBBLE_DURATION убираем wobble и «открываем» подарок
      timers.push(
        setTimeout(() => {
          updateAnimations({
            gift: '',
            giftCover: 'animate__open',
            giftContainer: 'animate__open',
            card: 'animate',
          })
        }, WOBBLE_DURATION),
      )

      // Автоматически закрываем через AUTO_CLOSE_DURATION (например, 5 секунд)
      timers.push(
        setTimeout(() => {
          // Сначала даём fade-out
          updateAnimations({ fade: 'fade-out' })

          // А через небольшую паузу закрываем попап
          timers.push(
            setTimeout(() => {
              closeModal()
            }, FADE_OUT_DURATION),
          )
        }, AUTO_CLOSE_DURATION),
      )
    }

    // Очистка всех таймеров при размонтировании/смене open
    return () => {
      timers.forEach(t => clearTimeout(t))
    }
  }, [open, closeModal, updateAnimations])

  // Выборка нужного подарка по индексу
  const adjustedIndex = (giftId || 1) - 1
  const giftIcon = giftIcons[adjustedIndex % giftIcons.length]
  const giftName = giftIconsName[adjustedIndex % giftIconsName.length]

  return (
    <div className={`giftAnimations ${animations.fade}`}>
      <div className='modal'>
        <div className='popup'>
          <div className='container'>
            <div className='container__content'>
              <Boom />
              <div className='giftAnimationsC-container'>
                <div className={`giftAnimationsC ${animations.gift}`}>
                  <div className={`giftAnimationsC__cover ${animations.giftCover}`}>
                    <div className='giftAnimationsC__cover__wrap' />
                  </div>
                  <div className={`giftAnimationsC__container ${animations.giftContainer}`}>
                    <div className='giftAnimationsC__container__wrap' />
                    <span className='giftAnimationsC__container__label'>Сюрприз!</span>
                  </div>
                </div>
              </div>

              <div className={`card ${animations.card}`}>
                {role === 'CLIENT' ? <h2>Вы отправили подарок!</h2> : <h2>Вам отправили подарок!</h2>}
                <img src={giftIcon} alt={giftName} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GiftAnimations
