import React, { useEffect, useState } from 'react'
import { ModelGetAllApiArg, ModelViewDto, useModelGetAllQuery, useRelationshipGetFullAllQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import Item from './Item/Item'
import './scss/index.scss'
import Filter from './Filter/Filter'
import { ActiveFilters, Filters } from './Filter/types' // Предполагаем, что типы определены в ./types
import Loading from '../../../elements/Loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { getSubscribeUser } from '../../../store/reducer/socket/selector'
import noData from 'images/img/no-data/3.png'
import useWebSocket from '../../../socket/useWebSocket'
import useSubscribeToNewUsers from '../../../hooks/socketTs/useSubscribeToNewUsers'
import { setModels } from 'store/reducer/user/reducer'

interface WindowSize {
  width: number
  height: number
}

const Girls = () => {
  const subscribeUser = useSelector(getSubscribeUser)
  const [payload, setPayload] = useState<ModelGetAllApiArg>({
    languages: [
      'ARABIC',
      'BULGARIAN',
      'CZECH',
      'DANISH',
      'GERMAN',
      'GREEK',
      'ENGLISH_BRITISH',
      'ENGLISH_AMERICAN',
      'SPANISH',
      'ESTONIAN',
      'FINNISH',
      'FRENCH',
      'HUNGARIAN',
      'INDONESIAN',
      'ITALIAN',
      'JAPANESE',
      'KOREAN',
      'LITHUANIAN',
      'LATVIAN',
      'NORWEGIAN_BOKMAL',
      'DUTCH',
      'POLISH',
      'PORTUGUESE_BRAZILIAN',
      'PORTUGUESE_PORTUGAL',
      'ROMANIAN',
      'RUSSIAN',
      'SLOVAK',
      'SLOVENIAN',
      'SWEDISH',
      'TURKISH',
      'UKRAINIAN',
      'CHINESE_SIMPLIFIED',
    ],
    isHaveRelationshipWithCurrentClient: undefined,
    relationshipStatuses: undefined,
    services: undefined,
    name: undefined,
    isOnline: undefined,
    showDeleted: undefined,
    showDisabled: undefined,
    ids: undefined,
    page: undefined,
    pageSize: 9999,
  })

  const { data, isLoading } = useModelGetAllQuery({ ...payload })

  const [idGirls, setIdGirls] = useState<string[]>([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (data) {
      const userIds = data.content.map(e => e.user?.id).filter((id): id is string => !!id)
      setIdGirls(userIds)
      dispatch(setModels(data?.content))
    }
  }, [data])

  const { sendSubscribe } = useWebSocket()
  const subscribedUserIds = subscribeUser?.body?.map(user => user.userId) || []
  const userId = idGirls.filter(id => !subscribedUserIds.includes(id))

  // Call the custom hook at the top level
  useSubscribeToNewUsers({
    userId,
    sendSubscribe,
    subscribeUser,
  })

  const filterUpdateData = (filter: ActiveFilters) => {
    function extractCountryCodes(items: Filters[]): string[] {
      return items.filter(item => item.active).map(item => item.value)
    }

    const resultLanguages: string[] = extractCountryCodes(filter.languages)
    const resultOnline = () => {
      if (filter?.online[0]?.active && filter?.online[1]?.active) {
        return undefined
      }
      if (filter?.online[0]?.active === true) {
        return true
      }
      if (filter?.online[0]?.active === false) {
        return false
      }
    }

    setPayload({
      ...payload,
      name: filter.name || undefined,
      // @ts-ignore
      languages: resultLanguages.length ? resultLanguages : undefined,
      isOnline: resultOnline(),
    })
  }

  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const { data: dataFriends, refetch: dataFriendsRefetch } = useRelationshipGetFullAllQuery({ statuses: ['BLOCKED'] })
  const { data: userMe } = useUserGetFullMeQuery()
  return (
    <div className='girls'>
      <div className='girls-filter'>
        <Filter filterUpdateData={filterUpdateData} />
      </div>
      {isLoading ? <Loading /> : null}
      <div className='girls__block'>
        <div className='girls__box'>
          {data !== undefined
            ? (() => {
                // Предварительная фильтрация данных
                const filteredData = data.content.filter((e: ModelViewDto) => {
                  const onlineStatus = subscribeUser?.body?.find(user => user.userId === e?.model?.id)?.activityStatus ?? 'noStatus'
                  const idNew = userMe?.userData?.user?.id
                  const result = dataFriends?.content.find(item => item?.relationship?.clientId === idNew)

                  // Проверка на блокировку
                  if (
                    result?.relationship?.initiatedBy === 'MODEL' &&
                    result?.relationship?.status === 'BLOCKED' &&
                    e?.user?.id === result?.model?.user?.id
                  ) {
                    return false
                  }

                  return true
                })

                // Если после фильтрации нет данных
                if (filteredData.length === 0) {
                  return (
                    <div className='no-data'>
                      <img src={noData} alt='' />
                      <p>Nothing found after adjusting the search!</p>
                    </div>
                  )
                }
                return (
                  <>
                    {filteredData.map((e: ModelViewDto) => {
                      const onlineStatus = subscribeUser?.body?.find(user => user.userId === e?.model?.id)?.activityStatus ?? 'noStatus'
                      return <Item key={e?.model?.id} item={e} onlineStatus={onlineStatus} />
                    })}
                  </>
                )
              })()
            : null}
        </div>
      </div>
    </div>
  )
}

export default Girls
