import { useEffect, useState } from 'react'
import {
  RelationshipFullDto,
  RelationshipGetFullAllApiArg,
  useRelationshipDeleteByIdMutation,
  useRelationshipGetFullAllQuery,
  useRelationshipPartialUpdateByIdMutation,
  useUserGetFullMeQuery,
} from 'store/apiMain/mainApi'
import { SvgCloseSearch, SvgSearchChat } from '../../../../images/svg'
import './scss/index.scss'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../../store/reducer/token/selector'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import Item from './Item/Item'
import Tabs from './Tabs/Tabs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getSocketNotification } from '../../../../store/reducer/socket/selector'
import { selectorUpdateFriends } from '../../../../store/reducer/friends/selectors'
import Loading from '../../../../elements/Loading/Loading'
import NoResults from 'UI/NoResults/NoResults'

const Content: any = ({ filter, refetchTabs }: any) => {
  const { t } = useTranslation()
  const role = useSelector(selectRole)
  const [search, setSearch] = useState('')
  const { data: user, refetch } = useUserGetFullMeQuery()
  const [activeTab, setActiveTab] = useState(undefined)
  const [payload, setPayload] = useState<RelationshipGetFullAllApiArg>({
    // @ts-ignore
    userId: (user as any)?.[role?.toLowerCase()]?.id || undefined,
    statuses: undefined,
    name: undefined,
    isFavorite: undefined,
    isOnline: undefined,
    showDeleted: undefined,
    showDisabled: undefined,
    ids: undefined,
    pointOfViewRole: undefined,
    initiatedBy: undefined,
    page: undefined,
    pageSize: undefined,
  })

  useEffect(() => {
    setPayload({ ...payload, name: search })
  }, [search])

  useEffect(() => {
    if (activeTab === undefined) {
      setPayload({ ...payload, initiatedBy: undefined })
    }
    if (activeTab === 'Sent') {
      setPayload({
        ...payload,
        initiatedBy: role === 'CLIENT' ? 'CLIENT' : 'MODEL',
      })
    }
    if (activeTab === 'Received') {
      setPayload({
        ...payload,
        initiatedBy: role === 'CLIENT' ? 'MODEL' : 'CLIENT',
      })
    }
  }, [activeTab])

  useEffect(() => {
    // @ts-ignore
    setPayload({ ...payload, userId: user?.[role.toLowerCase()]?.id })
  }, [user])

  useEffect(() => {
    if (filter === 'FRIENDS') {
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: 'FRIENDS',
        isFavorite: undefined,
        isOnline: undefined,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
    if (filter === 'FRIEND_REQUEST') {
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: 'FRIEND_REQUEST',
        isFavorite: undefined,
        isOnline: undefined,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
    if (filter === 'BLOCKED') {
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: 'BLOCKED',
        isFavorite: undefined,
        isOnline: undefined,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
    if (filter === 'ONLINE') {
      // @ts-ignore
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: undefined,
        isFavorite: undefined,
        isOnline: true,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
    if (filter === 'FAVORITES') {
      // @ts-ignore
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: undefined,
        isFavorite: true,
        isOnline: undefined,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
  }, [filter])

  const { data: dataFriends, refetch: dataFriendsRefetch, isLoading } = useRelationshipGetFullAllQuery({ ...payload })

  const updateFriends = useSelector(selectorUpdateFriends)

  useEffect(() => {
    dataFriendsRefetch()
  }, [updateFriends])
  const [friendsUpdate, { isSuccess: isSuccessUpdate }] = useRelationshipPartialUpdateByIdMutation()
  const [friendsDelete, { isSuccess: isSuccessDelete }] = useRelationshipDeleteByIdMutation()

  useEffect(() => {
    if (dataFriends) {
      dataFriendsRefetch()
    }
  }, [payload])

  useEffect(() => {
    if (isSuccessDelete) {
      refetchTabs()
      dataFriendsRefetch()
      toastSuccess('Request Canceled')
    }
  }, [isSuccessDelete])

  useEffect(() => {
    if (isSuccessUpdate) {
      refetchTabs()
      dataFriendsRefetch()
      toastSuccess('Success')
    }
  }, [isSuccessUpdate])

  const renderFriendsTitle = (text: string) => {
    if (text === 'FRIENDS') {
      return 'Friends'
    }
    if (text === 'FRIEND_REQUEST') {
      return 'Requests'
    }
    if (text === 'ONLINE') {
      return 'Online'
    }
    if (text === 'FAVORITES') {
      return 'Favorites'
    }
    if (text === 'BLOCKED') {
      return 'Blocked'
    }
  }

  const noContent = (filter: string) => {
    if ((role === 'CLIENT' && search !== '') || (role === 'MODEL' && search !== '')) return <NoResults />

    if (filter === 'FRIENDS') {
      if (role === 'CLIENT' && search === '') {
        return (
          <p>
            {t('noContent.noFriends')} <Link to='/girls'>{t('noContent.noFriends_girls')}</Link>
          </p>
        )
      }

      if (role === 'MODEL' && search === '') {
        return (
          <p>
            {t('noContent.noFriends')} <Link to='/model/people'>{t('noContent.noFriends_people')}</Link>
          </p>
        )
      }

      return null
    }

    if (filter === 'ONLINE') {
      return <p>{t('noContent.noFriendsOnline')}</p>
    }

    if (filter === 'FAVORITES') {
      return <p>{t('noContent.noFavoriteFriends')}</p>
    }

    if (filter === 'FRIEND_REQUEST') {
      if (activeTab === 'Sent') {
        return <p>{t('noContent.noSentFriendRequests')}</p>
      }

      if (activeTab === 'Received') {
        return <p>{t('noContent.noReceivedFriendRequests')}</p>
      }

      return <p>{t('noContent.noFriendRequests')}</p>
    }

    if (filter === 'BLOCKED') {
      return <p>{t('noContent.noBlockedFriends')}</p>
    }
  }

  const socketNotification = useSelector(getSocketNotification)
  useEffect(() => {
    dataFriendsRefetch()
  }, [socketNotification])

  const title = renderFriendsTitle(filter)

  return (
    <div
      className='friends__container'
      style={{
        backgroundImage: `linear-gradient(to right, rgba(255,255,255, 0.6) 0 100%), url('/chat_bg.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className='friends__header'>
        <div className='friends__header_flex'>
          <h3 className='friends__title'>{title}</h3>
          {dataFriends && dataFriends.totalElements > 0 ? (
            <div className='friends-count'>
              <span>{dataFriends.totalElements}</span>
            </div>
          ) : null}
          {filter === 'FRIEND_REQUEST' && <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />}
        </div>
        <search className='friends__search'>
          <input
            value={search}
            onChange={e => {
              setSearch(e.target.value)
            }}
            type='text'
            placeholder='Search...'
          />
          {search.length > 0 ? (
            <button className='sidebar__close-btn' onClick={() => setSearch('')}>
              <SvgCloseSearch />
            </button>
          ) : (
            <div>
              <SvgSearchChat />
            </div>
          )}
        </search>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {dataFriends && dataFriends.content && dataFriends.content.length > 0 ? (
            <div className='friends__content'>
              {dataFriends.content.map((e: RelationshipFullDto) => (
                <Item key={e.relationship?.id} item={e} filter={filter} role={role} friendsDelete={friendsDelete} friendsUpdate={friendsUpdate} />
              ))}
            </div>
          ) : (
            <div className='no-content'>{noContent(filter)}</div>
          )}
        </>
      )}
    </div>
  )
}

export default Content
