// PingPong.tsx

import React, { useEffect, useRef } from 'react'
import useWebSocket from '../../socket/useWebSocket'

const useTimit = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => {
        savedCallback.current && savedCallback.current()
      }, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const PingPong = () => {
  const { sendHeartbeat } = useWebSocket()

  // Send heartbeat every 30 seconds using useTimit
  useTimit(() => {
    sendHeartbeat()
  }, 3000) // 3000 ms = 3 seconds

  return null
}

export default PingPong
