import React, { FC, useCallback, useEffect, useState } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { IPopUp } from '../../types'
import './scss/index.scss'
import { LocalUserChoices, PreJoin } from '@livekit/components-react'
import { useDispatch, useSelector } from 'react-redux'
import { setVideoSettings, setVideoSettingsCheck } from 'store/reducer/videoSettings/reducer'
import { selectVideoSettings, selectVideoSettingsCheck } from 'store/reducer/videoSettings/selector'
import notifications from '../../../../elements/Notifications/Notifications'
import { toastSuccess } from '../../../../elements/Notification/Notification'

const SettingsVideo: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const [preJoinDefaults, setPreJoinDefaults] = useState({
    username: 'test',
    videoEnabled: true,
    audioEnabled: true,
  })
  const [preJoinChoices, setPreJoinChoices] = useState<LocalUserChoices | undefined>(undefined)
  const videoSettings = useSelector(selectVideoSettings)
  const videoSettingsCheck = useSelector(selectVideoSettingsCheck)
  const handlePreJoinSubmit = useCallback((values: LocalUserChoices) => {
    setPreJoinChoices(values)
    dispatch(setVideoSettings(values))
    dispatch(setVideoSettingsCheck(true))
  }, [])
  useEffect(() => {
    if (videoSettingsCheck) {
      dispatch(setVideoSettingsCheck(false))
      toastSuccess('Сохранено!')
      closeModal()
    }
  }, [videoSettings])
  const onPreJoinError = useCallback((e: any) => {
    console.error(e)
  }, [])
  return (
    <>
      <Popup
        closeOnDocumentClick={false} // Позволяет закрывать модальное окно при клике вне его области
        closeOnEscape={false}
        open={open}
        onClose={closeModal} // Важно передать функцию закрытия
      >
        <div className='modal'>
          <div className='popup'>
            <div className='SettingsVideo'>
              <h2>Настройка видео и звука!</h2>
              <main data-lk-theme='default'>
                <div
                  style={{
                    display: 'grid',
                    placeItems: 'center',
                    height: '100%',
                  }}
                >
                  <PreJoin onError={onPreJoinError} defaults={preJoinDefaults} onSubmit={handlePreJoinSubmit} joinLabel={'Сохранить'} />
                </div>
              </main>
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default SettingsVideo
