import React, { FC, useEffect } from 'react'
import Popup from 'reactjs-popup'
import { IPopUp } from '../../types'
import './scss/index.scss'
import { useSelector } from 'react-redux'
import { selectCameraState, selectMicrophoneState } from 'store/reducer/permissionMonitor/selectors'
import { SvgFinger, SvgMicrophoneOff, SvgVideoOff } from '../../../../images/svg'
import { toastSuccess } from '../../../../elements/Notification/Notification'

type PermissionState = 'granted' | 'denied' | 'prompt'

const MediaPermissionMonitorPopUp: FC<IPopUp> = ({ open, closeModal }) => {
  const cameraState = useSelector(selectCameraState) as PermissionState
  const microphoneState = useSelector(selectMicrophoneState) as PermissionState
  useEffect(() => {
    if (open) {
      if (cameraState === 'granted' && microphoneState === 'granted') {
        toastSuccess('Теперь можно звонить/принимать звонки!')
        closeModal()
      }
    }
  }, [cameraState, microphoneState, open, closeModal])

  const missingPermissions: string[] = []
  if (cameraState !== 'granted') missingPermissions.push('camera')
  if (microphoneState !== 'granted') missingPermissions.push('microphone')

  const headerText =
    missingPermissions.length === 1
      ? `Access to ${missingPermissions[0]} is blocked or ${missingPermissions[0]} is unavailable`
      : `Access to ${missingPermissions.join(' and ')} is blocked or unavailable`

  return (
    <Popup open={open} closeOnDocumentClick={false} closeOnEscape={false} modal>
      <div className='modal'>
        <div className='popup'>
          <div className='MediaPermissionMonitorPopUp'>
            <div className='MediaPermissionMonitorPopUp_help'>
              <SvgFinger />
            </div>
            <div className='MediaPermissionMonitorPopUp-header'>
              <div className='MediaPermissionMonitorPopUp-header_icons'>
                {microphoneState !== 'granted' && (
                  <div className='MediaPermissionMonitorPopUp-header_icons-microphone'>
                    <SvgMicrophoneOff />
                  </div>
                )}
                {cameraState !== 'granted' && (
                  <div className='MediaPermissionMonitorPopUp-header_icons-camera'>
                    <SvgVideoOff />
                  </div>
                )}
              </div>
              <h1>{headerText}</h1>
            </div>
            <div className='MediaPermissionMonitorPopUp-content'>
              <ol>
                <li>
                  Allow access to the camera and microphone in your browser by clicking the <strong>Site Settings</strong> icon →{' '}
                  <strong>Camera</strong> and <strong>Microphone</strong> and set Allow in the dropdown menu.
                </li>
                <li>
                  Close all programs that may be using the camera or microphone. If the problem does not resolve with an external camera or
                  microphone, disconnect the cable from the computer, wait a few seconds, and then securely reconnect the cable to the computer.
                </li>
              </ol>
            </div>
            <div className='MediaPermissionMonitorPopUp-footer'>
              <button className='try-again-button' onClick={closeModal}>
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default MediaPermissionMonitorPopUp
