import { t } from 'i18next'
import { SvgSelectChat } from 'images/svg'
import './scss/index.scss'

const NoId = () => {
  return (
    <div className='no-id'>
      <div className='no-id-block'>
        <SvgSelectChat />
        <p>{t('Select a chat...')}</p>
      </div>
    </div>
  )
}

export default NoId
