import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { mainApi as injectedRtkApi } from './apiMain/mainApi'

// Импорт всех ваших редьюсеров
import tokenSlice from './reducer/token/reducer'
import socketSlice from './reducer/socket/reducer'
import socketTestDataSlice from './reducer/socketTest/reducer'
import stepsSlice from './reducer/steps/reducer'
import fileArraySlice from './reducer/fileArray/reducer'
import popUpSlice from './reducer/popUp/reducer'
import ageConfirmationSlice from './reducer/age/reducer'
import languageSlice from './reducer/language/reducer'
import globalSlice from './reducer/global/reducer'
import modelsSlice from './reducer/models/reducer'
import clientsSlice from './reducer/clients/reducer'
import messagesSlice from './reducer/messages/reducer'
import friendsSlice from './reducer/friends/reducer'
import accountControllerSlice from './reducer/accountController/reducer'
import usersSlice from './reducer/user/reducer'
import notificationsSlice from './reducer/notifications/reducer'
import videoChatSlice from './reducer/videoChat/reducer'
import roomsSlice from './reducer/rooms/reducer'
import videoSettingsSlice from './reducer/videoSettings/reducer'
import videoChatWithdrawalMoneySlice from './reducer/videoChatWithdrawalMoney/reducer'
import logoutSlice from './reducer/logout/reducer'
import permissionMonitorSlice from './reducer/permissionMonitor/reducer'

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['token', 'age', 'language', 'videoChat', 'videoSettings'],
}

const appReducer = combineReducers({
  [injectedRtkApi.reducerPath]: injectedRtkApi.reducer,
  token: tokenSlice,
  socket: socketSlice,
  socketTestData: socketTestDataSlice,
  language: languageSlice,
  fileArray: fileArraySlice,
  steps: stepsSlice,
  popUp: popUpSlice,
  global: globalSlice,
  models: modelsSlice,
  clients: clientsSlice,
  age: ageConfirmationSlice,
  messages: messagesSlice,
  videoChat: videoChatSlice,
  friends: friendsSlice,
  account: accountControllerSlice,
  users: usersSlice,
  notifications: notificationsSlice,
  rooms: roomsSlice,
  videoSettings: videoSettingsSlice,
  videoChatWithdrawalMoney: videoChatWithdrawalMoneySlice,
  permissionMonitor: permissionMonitorSlice,
  logout: logoutSlice,
})

const rootReducer = (state, action) => {
  if (action.type === 'logout/setLogout') {
    state = {
      age: state.age,
    }
  }
  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(injectedRtkApi.middleware),
})

export const persistor = persistStore(store)
