import Item from 'Components/User/Girl/Item'
import { useEffect } from 'react'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import './scss/index.scss'
import { useNavigate } from 'react-router-dom'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import { useDispatch, useSelector } from 'react-redux'
import useWebSocket from '../../../../socket/useWebSocket'
import { selectRole, selectTokenData } from '../../../../store/reducer/token/selector'
import { useCreateRoom } from '../../../Global/VideoChat/hook/useCreateRoom'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { getSelectorSetMessagePrivetChats } from 'store/reducer/messages/selectors'
import { setVideoRoomCreatedByModel } from 'store/reducer/videoChat/reducer'
import { useMediaPermissions } from '../../../../hooks/useMediaPermissions'

const MainInfo = () => {
  const { sendActivityUpdate } = useWebSocket()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(selectTokenData)
  const { data, isLoading, isError } = useUserGetFullMeQuery()
  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  const addFriends = () => {
    toastSuccess('Себя нельзя добоавлять в друзья!')
  }
  const addFavorites = () => {
    toastSuccess('Себя нельзя добоавлять в избранное!')
  }
  const addDelete = () => {
    toastSuccess('Себя нельзя удалять из друзей!')
  }
  const { handleCreateRoom, isLoading: isLoadingRoomCreate, isError: isErrorRoomCreate, isSuccess: isSuccessRoomCreate, error } = useCreateRoom()

  // const videoChatToken = useSelector(selectVideoChatObg)?.room?.id
  // const role = useSelector(selectRole)
  // useEffect(() => {
  //   if (videoChatToken && role === 'MODEL') {
  //     // @ts-ignore
  //     navigate(`/model/profile/main-info/room/${videoChatToken}`)
  //   }
  // }, [videoChatToken])
  const { ensurePermissionsAndExecute } = useMediaPermissions()
  return (
    <>
      <div className='main-info'>
        <div className='main-info-block'>
          <button
            onClick={() => {
              ensurePermissionsAndExecute(() => {
                dispatch(setVideoRoomCreatedByModel(true))
                handleCreateRoom()
              })
            }}
            className='rooms'
          >
            {!isLoadingRoomCreate ? 'Создать комнату' : 'Loading'}
          </button>
        </div>
        {data?.model !== undefined ? (
          <Item data={data} addFriends={addFriends} addFavorites={addFavorites} addDelete={addDelete} role={'model'} />
        ) : null}
        <div className='main-info-block'>
          <button
            onClick={() => {
              navigate('/model/profile/main-info/edit')
            }}
          >
            Редактировать
          </button>
        </div>
      </div>
    </>
  )
}

export default MainInfo
