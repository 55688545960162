import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { PrivateChatCreateApiArg, usePrivateChatCreateMutation } from 'store/apiMain/mainApi'
import { getSelectorSetMessagePrivetChats } from '../../../../../store/reducer/messages/selectors'
import './scss/index.scss'
import { setMessagesSelectedId, setMessagesSelectedStaffFalse, setMessagesSelectedUser } from 'store/reducer/messages/reducer'
import { t } from 'i18next'
import { selectStaffs } from 'store/reducer/user/selectors'
import { findMatchingContent } from '../../func/findMatchingContent'
import { SvgSendMessage } from 'images/svg'

const Support = () => {
  const [createAdminPrivateChat, { isSuccess }] = usePrivateChatCreateMutation()
  const dispatch = useDispatch()
  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  const staffs = useSelector(selectStaffs)

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessagesSelectedStaffFalse())
    }
  }, [isSuccess])
  const supportChat = dataPrivateChats?.content.find(e => e.privateChat.type === 'SUPPORT')
  // @ts-ignore
  const { matchingStaffContent } = findMatchingContent(supportChat, null, null, staffs)

  useEffect(() => {
    if (supportChat) {
      if (matchingStaffContent) {
        dispatch(setMessagesSelectedUser(matchingStaffContent))
        dispatch(setMessagesSelectedStaffFalse())
        dispatch(setMessagesSelectedId(`${supportChat.privateChat.id}`))
      }
    }
  }, [supportChat])
  return (
    <div className='chat-form__container'>
      <Formik
        initialValues={{ messageText: '' }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (!values.messageText.trim()) {
            setSubmitting(false)
            return
          }
          const payload: PrivateChatCreateApiArg = {
            privateChatCreateRequest: {
              initialMessage: {
                body: values.messageText,
              },
              type: 'SUPPORT',
            },
          }
          await createAdminPrivateChat(payload)
          resetForm()
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form className='chat-form'>
            <div className='chat-form__inner'>
              <Field type='text' name='messageText' placeholder={t('message.addTextAdmin')} />
              <div className='input__volume'></div>
              <button type='submit' disabled={isSubmitting}>
                <SvgSendMessage />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Support
