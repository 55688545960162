import React, { FC, useEffect } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { useDispatch } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import errorSocketImg from '../../../images/img/error.png'
import useLockBodyScroll from 'hooks/useLockBodyScroll'
import { popUpOpen } from 'store/reducer/popUp/reducer'

const ErrorSocket: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  useLockBodyScroll(open)

  useEffect(() => {
    if (!open) {
      dispatch(popUpOpen(''))
    }
  }, [open, dispatch])

  const handleRefresh = () => {
    window.location.reload()
  }

  const handleClose = () => {
    window.close()
  }

  return (
    <Popup
      closeOnEscape={false}
      closeOnDocumentClick={false} // Отключаем закрытие при клике вне попапа
      open={open}
      onClose={() => closeModal()}
    >
      <div className='modal'>
        <div className='popup'>
          <div className='ErrorSocket'>
            <div className='ErrorSocket-block'>
              <h2>Проблема с подключением! :(</h2>
              <img src={errorSocketImg} alt='Ошибка подключения' />
              <div className='ErrorSocket-buttons'>
                <button onClick={handleRefresh} className='refresh-button'>
                  Обновить страницу
                </button>
                <button onClick={handleClose} className='close-button'>
                  Закрыть страницу
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default ErrorSocket
